import createReducer from "../../lib/createReducer";
import {
  GET_CHART_LOADING,
  LINE_CHART_SUCCESS,
  GET_CHART_ERROR,
  PIE_CHART_SUCCESS,
  SMALL_CHART_SUCCESS,
  GET_PIE_DATA_SUCCESS,
  GET_PIE_DATA_ERROR,
  PIE_CHART_LIST_SUCCESS,
} from "./types";

let initialState = {
  loading: false,
  error: "",
  smallChartData: "",
  lineChartData: "",
  pieChartData: "",
  pieChartList: "",
  pieDataError: "",
};

export const analyticsReducer = createReducer(initialState, {
  // Chart Types
  [GET_CHART_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
      error: "",
    });
  },
  [GET_CHART_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      error: action.payload,
    });
  },
  [SMALL_CHART_SUCCESS](state, action) {
    return Object.assign({}, state, {
      smallChartData: action.payload,
    });
  },
  [LINE_CHART_SUCCESS](state, action) {
    return Object.assign({}, state, {
      lineChartData: action.payload,
    });
  },
  [PIE_CHART_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      pieChartList: action.payload,
    });
  },
  [PIE_CHART_SUCCESS](state, action) {
    return Object.assign({}, state, {
      pieChartData: action.payload,
    });
  },

  // GET PIE DATA
  [GET_PIE_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      pieChartData: action.payload,
      pieDataError: "",
    });
  },
  [GET_PIE_DATA_ERROR](state, action) {
    return Object.assign({}, state, {
      pieChartData: "",
      pieDataError: action.payload,
    });
  },
});
