import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import { Error, PageSpinner, ActivityModal, InviteMemberModal } from "../index";
import { useDispatch, useSelector } from "react-redux";
import { PermissionCheck } from "../index";
import {
  changePermissionAction,
  getActivityAction,
} from "../../views/Settings/action";

const AccessHub = ({ isAdmin, orgUsersData }) => {
  let dispatch = useDispatch();

  const { permissionLoading, permissionError, permissionSuccess } = useSelector(
    (state) => ({
      permissionLoading: state.settingsReducer.permissionLoading,
      permissionSuccess: state.settingsReducer.permissionSuccess,
      permissionError: state.settingsReducer.permissionError,
    })
  );

  const [inviteModal, setInviteModal] = useState(false);
  const [activityModal, setActivityModal] = useState(false);

  const [permissionsModal, setPermissionsModal] = useState(false);
  const [permissionsModalData, setPermissionsModalData] = useState("");

  // FOR INITIAL
  useEffect(() => {
    if (permissionSuccess) {
      setPermissionsModal(false);
    }
  }, [permissionSuccess]);

  // FUNCTIONS
  const handlePermissionClick = (user_id, candidate_group_id, permission) => {
    let data = {
      user_id,
      candidate_group_id,
      permission_type: permission.permission_type,
      allowed: !permission.allowed,
    };
    dispatch(changePermissionAction(data));
  };

  const handleActivityClick = (user) => {
    setActivityModal(true);
    dispatch(getActivityAction({ userId: user.user_id }));
  };

  return (
    <>
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Access Hub</h6>
        </CardHeader>
        <CardBody>
          {/* all access data */}
          <table style={styles.tableStyle}>
            <tbody>
              {orgUsersData
                ? orgUsersData.map((orgUser, i) => (
                    <tr key={i}>
                      <td style={styles.tableItem}>
                        <span>{orgUser.email}</span>
                      </td>
                      <td style={styles.tableItem}>
                        <Button
                          theme="secondary"
                          onClick={() => {
                            setPermissionsModal(true);
                            setPermissionsModalData(orgUser);
                          }}
                        >
                          View Permissions
                        </Button>
                      </td>
                      <td style={styles.tableItem}>
                        <Button
                          theme="secondary"
                          onClick={() => handleActivityClick(orgUser)}
                        >
                          View Activity
                        </Button>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>

          {/* invite link */}
          <div className="pt-3">
            <span
              className="font-italic"
              onClick={() => {
                if (isAdmin) setInviteModal(true);
              }}
              style={{
                cursor: isAdmin ? "pointer" : "default",
                color: isAdmin ? "#555" : "#ccc",
              }}
            >
              &#43; Invite organization member
            </span>
          </div>
        </CardBody>
      </Card>

      {/* PERMISSION MODAL */}
      <Modal
        open={permissionsModal}
        toggle={() => setPermissionsModal(!permissionsModal)}
        centered
      >
        <ModalHeader>Permissions</ModalHeader>
        {permissionLoading ? (
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <PageSpinner />
          </div>
        ) : (
          <>
            {permissionError && <Error error message={permissionError} />}
            <ModalBody>
              {permissionsModalData.permissions &&
              permissionsModalData.permissions.length > 0 ? (
                permissionsModalData.permissions.map((orgUser, i) => (
                  <Row className="mb-4" key={i}>
                    <Col>
                      <span className="textSelect-none">
                        {orgUser.candidate_group_name}
                      </span>
                    </Col>
                    {orgUser.permissions && orgUser.permissions.length > 0
                      ? orgUser.permissions.map((permission, i) => (
                          <Col key={i}>
                            <PermissionCheck
                              message={permission.permission_text}
                              isChecked={permission.allowed}
                              handlePermissionClick={() =>
                                handlePermissionClick(
                                  permissionsModalData.user_id,
                                  orgUser.candidate_group_id,
                                  permission
                                )
                              }
                            />
                          </Col>
                        ))
                      : null}
                  </Row>
                ))
              ) : (
                <span>No permissions to show</span>
              )}
            </ModalBody>
          </>
        )}
      </Modal>

      {/* ACTIVITY MODAL */}
      <ActivityModal
        open={activityModal}
        toggle={() => setActivityModal(!activityModal)}
      />

      {/* INVITE MEMBER MODAL */}
      <InviteMemberModal
        open={inviteModal}
        toggle={() => setInviteModal(!inviteModal)}
        setModal={(value) => setInviteModal(value)}
      />
    </>
  );
};

const styles = {
  tableStyle: { tableLayout: "auto", width: "100%" },
  tableItem: {
    padding: 5,
  },
};

export default AccessHub;
