import React, { useState, useEffect, useCallback } from "react";
import { CheckCard, MonitoringCard } from "../index";
import { useSelector, useDispatch } from "react-redux";
import { updateMonitoringAction } from "../../views/Candidates/action";

const UserExtraInfo = ({ userDetail }) => {
  const dispatch = useDispatch();

  const [monitoring, setMonitoring] = useState(
    userDetail.traqcheck_id.continuous_monitoring_enabled
  );

  const { monitoringLoading, monitoringSuccess } = useSelector((state) => ({
    monitoringLoading: state.candidatesReducer.monitoringLoading,
    monitoringSuccess: state.candidatesReducer.monitoringSuccess,
  }));

  const monitoringCallback = useCallback(() => {
    setMonitoring(!monitoring);
  }, [monitoring]);
  useEffect(() => {
    if (monitoringSuccess) {
      monitoringCallback();
    }
    // eslint-disable-next-line
  }, [monitoringSuccess]);

  // FUNCTIONS
  const handleMonitoring = () => {
    dispatch(
      updateMonitoringAction({
        check_id: userDetail.check_id,
        continuous_monitoring: !monitoring,
      })
    );
  };

  return (
    <>
      <CheckCard
        userDetail={userDetail}
        checksList={userDetail.checks_conducted}
      />
      <MonitoringCard
        userDetail={userDetail}
        monitoringValue={monitoring}
        monitoringChange={() => handleMonitoring()}
        loading={monitoringLoading}
      />
    </>
  );
};

export default UserExtraInfo;
