import coreApi from "../../lib/coreApi";

export let createCheckApi = (data) => {
  let url = "checks";
  let result = coreApi.POST(url, data);
  return result;
};

export let getEstimatesForCheck = (data) => {
  let url = "check_estimates";
  let result = coreApi.POST(url, data);
  return result;
};
