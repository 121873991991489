import { Constant } from "./Constants";
import { saveAs } from "file-saver";
let domainUrl = Constant.BASE_URL;

const coreAPI = {
  OPEN: (link) => {
    const token = localStorage.getItem("token");
    window.open(`${Constant.BASE_URL}${link}?hash=${token}`, '_blank');
  },
  GET: (link, notTablet) =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");

      let url;
      if (notTablet) url = Constant.BASE_URL + link;
      else url = domainUrl + link;

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: token ? `token ${token}` : "",
        },
      })
        .then((response) => {
          if (response.status === 500) throw response.statusText;
          return response.json();
        })
        .then((responseText) => {
          if (responseText?.detail === "Invalid token.") {
            localStorage.removeItem("token");
          }
          resolve(responseText);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  POST: (link, data) =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      const url = domainUrl + link;

      fetch(url, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Authorization: token ? `token ${token}` : "",
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 500) throw response.statusText;
          return response.json();
        })
        .then((responseText) => {
          if (responseText?.detail === "Invalid token.") {
            localStorage.removeItem("token");
          }
          resolve(responseText);
        })
        .catch((error) => {
          reject(error);
        });
    }),
  PUT: (link, data) =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      const url = domainUrl + link;

      fetch(url, {
        body: JSON.stringify(data),
        method: "PUT",
        headers: {
          Authorization: token ? `token ${token}` : "",
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 500) throw response.statusText;
          return response.json();
        })
        .then((responseText) => {
          if (responseText?.detail === "Invalid token.") {
            localStorage.removeItem("token");
          }
          resolve(responseText);
        })
        .catch((error) => {
          reject(error);
        });
    }),
  PATCH: (link, data) =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      const url = domainUrl + link;

      fetch(url, {
        body: JSON.stringify(data),
        method: "PATCH",
        headers: {
          Authorization: token ? `token ${token}` : "",
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 500) throw response.statusText;
          return response.json();
        })
        .then((responseText) => {
          if (responseText?.detail === "Invalid token.") {
            localStorage.removeItem("token");
          }
          resolve(responseText);
        })
        .catch((error) => {
          reject(error);
        });
    }),
  DOWNLOAD_ZIP: (link, data) =>
    new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      const url = domainUrl + link;

      let groupName = data?.groupName || "";
      if (data?.groupName) delete data.groupName;

      fetch(url, {
        body: JSON.stringify(data),
        method: "POST",
        headers: {
          Authorization: token ? `token ${token}` : "",
          "Content-type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            const blobData = new Blob([blob]);
            let fileName = "Candidates group.zip";
            if (groupName) fileName = `${groupName}.zip`;
            saveAs(blobData, fileName);
            resolve(true);
          } else throw Error("Something went wrong.Please try again");
        })
        .catch((error) => {
          reject(error);
        });
    }),
};

export default coreAPI;
