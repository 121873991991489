import React, { useState } from "react";
import { NavLink as RouteNavLink } from "react-router-dom";
import {
  Dropdown,
  DropdownMenu,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";
import { Dispatcher, Constants } from "../../../flux";

const SidebarDropdown = ({ item }) => {
  const [visible, setVisible] = useState(false);

  const toggleUserActions = () => {
    setVisible(!visible);
  };

  const handleNavToggle = () => {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  };

  return (
    <>
      <NavItem tag={Dropdown} caret className="nav-item">
        <NavLink
          className="nav-item-collapse pointer"
          onClick={toggleUserActions}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {item.htmlBefore && (
                <div
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                />
              )}
              {item.title && (
                <span className="d-md-inline-block">{item.title}</span>
              )}
            </div>
            <span
              style={{
                transform: visible ? "rotate(-90deg)" : "rotate(90deg)",
                transitionDuration: "0.3s",
                transitionProperty: "transform",
                fontSize: 15,
                color: "#aaa",
              }}
            >
              ❯
            </span>
          </div>
        </NavLink>
      </NavItem>
      <Collapse tag={DropdownMenu} open={visible}>
        {item.subItems
          ? item.subItems.map((link, i) => (
              <NavItem key={i}>
                <NavLink
                  tag={RouteNavLink}
                  to={link.to}
                  onClick={() => handleNavToggle()}
                >
                  {link.title && (
                    <span style={{ marginLeft: 27 }}>{link.title}</span>
                  )}
                </NavLink>
              </NavItem>
            ))
          : null}
      </Collapse>
    </>
  );
};
export default SidebarDropdown;
