import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Button,
} from "shards-react";
import etaImg from "../../assets/candidateDetail/eta.png";
import priceImg from "../../assets/candidateDetail/price.png";

const SubmitCheck = ({ handleCheckSubmit, eta, price, aiComments }) => {
  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0 blackText">Submit Check</h6>
      </CardHeader>
      <CardBody
        style={{ paddingBottom: 30, paddingTop: 30 }}
        className="border-bottom"
      >
        <div className="centerimageandtext">
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: "2em",
              }}
            >
              {eta ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <img src={etaImg} alt="Eta" height="60" />
                  </div>
                  <span style={{ marginLeft: "10px", marginTop: "10px" }}>
                    {eta}
                  </span>
                </div>
              ) : null}
            </div>
          </Row>
        </div>
      </CardBody>
      <CardFooter className="mb-3">
        <Row>
          <div
            className="pt-3 mb-2"
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <Button theme="primary" onClick={() => handleCheckSubmit()}>
              TraqCheck
            </Button>
          </div>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default SubmitCheck;
