import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Form,
  FormGroup,
  FormInput,
  FormFeedback,
  Button,
} from "shards-react";
import { Spinner } from "react-bootstrap";
import traqCheck_logo from "../../assets/traqCheck_logo_small.png";
import { useDispatch, useSelector } from "react-redux";
import { Error } from "../../components";
import { loginAction } from "./action";
import { invalidEmail, invalidPassword } from "../../lib/helper";

const Login = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const { error, loading } = useSelector((state) => ({
    error: state.loginReducer.error,
    loading: state.loginReducer.loading,
  }));

  // FUNCTIONS
  const submitForm = () => {
    if (invalidEmail(email) || invalidPassword(password, true)) {
      setErrors({
        email: invalidEmail(email),
        password: invalidPassword(password, true),
      });
    } else {
      setErrors({ email: "", password: "" });
      dispatch(loginAction({ email, password, history: props.history }));
    }
  };

  const handleForgotPassword = () => {
    history.push("/forgotPassword");
  };

  return (
    <>
      {error && <Error error message={error} />}
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f6f8",
          flexDirection: "column",
        }}
      >
        <Card
          style={{
            width: 300,
            backgroundColor: "#ffffff",
            borderTop: "4px solid #007aff",
            borderRadius: "1rem",
            padding: 20,
            paddingTop: 0,
          }}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <div className="text-center pb-2 pt-4">
              <img
                src={traqCheck_logo}
                style={{
                  width: 50,
                  height: 50,
                }}
                alt="logo"
              />
            </div>
            <div className="text-center pb-4">
              <span style={{ color: "#333" }}>Access your account</span>
            </div>

            {/* INPUTS */}
            <FormGroup>
              <label htmlFor="email">Email address</label>
              <FormInput
                id="email"
                name="email"
                placeholder="Enter email"
                invalid={errors.email.length > 0}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email ? (
                <FormFeedback>{errors.email}</FormFeedback>
              ) : null}
            </FormGroup>
            <FormGroup>
              <label htmlFor="password">Password</label>
              <FormInput
                id="password"
                name="password"
                type="password"
                placeholder="your password"
                className="input"
                invalid={errors.password.length > 0}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password ? (
                <FormFeedback>{errors.password}</FormFeedback>
              ) : null}
            </FormGroup>
            <div className="text-center mt-4 mb-2">
              <Button
                theme="primary"
                style={{ borderRadius: 100, minWidth: 100 }}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Access Account"
                )}
              </Button>
            </div>
          </Form>
        </Card>
        <div className="mt-2">
          <span
            style={{
              color: "#555",
              fontSize: 13,
              cursor: "pointer",
              fontWeight: "normal",
            }}
            onClick={handleForgotPassword}
          >
            Forgot your password?
          </span>
        </div>
      </div>
    </>
  );
};

export default Login;
