import React from "react";
import SmallStats from "../common/SmallStats";
import {
  smallChart_style,
  smallChart_randomData,
  smallChart_emptyLabels,
} from "../../lib/dummyData";

const SmallChart = ({ data, index }) => {
  return (
    <SmallStats
      variation="1"
      label={data.chart_title}
      chartData={smallChart_style.map((_, i) => ({
        ...smallChart_style[i],
        ...smallChart_randomData[index],
      }))}
      chartLabels={smallChart_emptyLabels}
      value={data.value}
      percentage={data.change_percent ? `${data.change_percent}%` : ""}
      increase={parseFloat(data.change_percent) > 0}
      decrease={parseFloat(data.change_percent) < 0}
    />
  );
};

export default SmallChart;
