import {
  mainLineStyle,
  otherLineStyle,
  otherLineColors,
  backgroundColor,
} from "./dummyData";

export const invalidEmail = (email, removeEmailRegex) => {
  if (!email) return "Please enter email";
  // eslint-disable-next-line
  // const reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z]+)\.([a-zA-Z]+)$/;
  const reg = /^(.+)@(.+)$/;
  const checkEmail = reg.test(email);
  if (!checkEmail && !removeEmailRegex) return "must be an email";
  return "";
};

export const invalidPassword = (password, removeLength) => {
  if (!password) return "Please enter password";
  if (password.length <= 3 && !removeLength)
    return "Password must be greater than 3 character";
  return "";
};

export const invalidConfPassword = (pass, confPass) => {
  if (!confPass) return "Please enter confirm password";
  if (pass !== confPass) return "Old password and new password doesn't match";
  return "";
};

export const invalidPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "Please enter phoneNumber";
  const reg = /^([0-9]{10})$/;
  const checkPhoneNumber = reg.test(phoneNumber);
  if (!checkPhoneNumber) return "Invalid phonenumber";
  return "";
};

export const invalidText = (text, field) => {
  if (!text) return `Please enter ${field}`;
  return "";
};

export const inputInvalid = (all, single) => {
  if (!single) return false;
  if (all && single.length > 0) return true;
  else return false;
};

export const delay = (time = 3000) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isAllTrue = (arr) => arr.every((a) => !a);

export const convertToLineFormat = (lineData) => {
  return new Promise((resolve, reject) => {
    try {
      let charts = [];
      charts = lineData.map((main) => {
        let x = {};
        x.title = main.chart_title;
        x["labels"] = Array.from(new Array(31), (_, i) => i + 1);
        x["datasets"] = [];
        x.datasets.push({
          label: main.main_line.line_title,
          data: Array.from(new Array(31), (_, i) => {
            i = i + 1;
            i = i.toString();
            if (Object.keys(main.main_line.values).includes(i)) {
              return main.main_line.values[i];
            } else {
              return 0;
            }
          }),
          ...mainLineStyle,
        });

        if (main.other_lines && main.other_lines.length > 0) {
          let i = 0;
          for (const other of main.other_lines) {
            x.datasets.push({
              label: other.line_title,
              data: Array.from(new Array(31), (_, i) => {
                i = i + 1;
                i = i.toString();
                if (Object.keys(other.values).includes(i)) {
                  return other.values[i];
                } else {
                  return 0;
                }
              }),
              ...otherLineStyle,
              ...otherLineColors[i],
            });
            i = i + 1;
          }
        }
        return x;
      });
      resolve(charts);
    } catch (error) {
      reject(error.toString());
    }
  });
};

export const convertToPieFormat = (pieData, title = "none") => {
  return new Promise((resolve, reject) => {
    try {
      let x = {
        label: title,
        data: {
          datasets: [
            {
              data: Object.values(pieData),
              backgroundColor,
            },
          ],
          labels: Object.keys(pieData),
        },
      };
      resolve(x);
    } catch (error) {
      reject(error.toString());
    }
  });
};

export const cleanText = (text, reverse = false) => {
  let newText = "";
  if (reverse) {
    newText = text.replace(" ", "_");
  } else {
    newText = text.replace("_", " ");
  }
  return newText;
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    } else {
      reject("no file");
    }
  });
};
