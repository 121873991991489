import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import SidebarDropdown from "./SidebarDropdown";

const SidebarNavItems = (props) => {
  const items = props.sidebarNavItems;

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {items.map((item, index) => {
          if (!item.subItems) return <SidebarNavItem key={index} item={item} />;
          else return <SidebarDropdown key={index} item={item} />;
        })}
      </Nav>
    </div>
  );
};

export default SidebarNavItems;
