import { applyMiddleware, compose, createStore } from "redux";
// import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers/index";
import { rootSaga } from "../saga/rootSaga";

const sagaMiddleware = createSagaMiddleware();
// const loggerMiddleware = createLogger({});

function configureStore(initialState) {
  const middleWare = compose(applyMiddleware(sagaMiddleware));
  return createStore(reducers, initialState, middleWare);
}

const store = configureStore({});
sagaMiddleware.run(rootSaga);
export default store;
