import React, { useEffect } from "react";
import {useHistory} from "react-router-dom";
import coreApi from "../../lib/coreApi";

const DownloadMis = () => {

  const history = useHistory();

  useEffect(() => {
    coreApi.OPEN('download-mis')
    history.goBack();
  }, [history]);

  return (<div />);

};

export default DownloadMis;
