import { LOGIN_ACTION, FORGOT_PASSWORD_ACTION } from "./types";

export const loginAction = (data) => ({
  type: LOGIN_ACTION,
  payload: data,
});

export const forgotPasswordAction = (data) => ({
  type: FORGOT_PASSWORD_ACTION,
  payload: data,
});
