import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormInput,
  FormGroup,
  Button,
  FormFeedback,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAction } from "../Settings/action";
import { Spinner } from "react-bootstrap";
import { Error } from "../../components";

function ChangeWebhook() {
  let history = useHistory();
  let dispatch = useDispatch();

  const [newWebhookUrl, setNewWebhookUrl] = useState("");
  const [errors, setErrors] = useState({
    newWebhookUrl: "",
  });

  const { loading, success, error } = useSelector((state) => ({
    loading: state.settingsReducer.loading,
    success: state.settingsReducer.success,
    error: state.settingsReducer.error,
  }));

  // FUNCTIONS
  const handleSubmit = (e) => {
    e.preventDefault();
    if (false) {
      // Do something here maybe to verify webhook URL.
    } else {
      setErrors({ newWebhookUrl: "" });
      dispatch(
        changePasswordAction({
          new_webhook_url: newWebhookUrl,
          history,
        })
      );
    }
  };

  return (
    <>
      {error && <Error error message={error} />}
      {success && <Error success message={success} />}
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/* back button */}
          <i
            className="fas fa-arrow-left mr-3"
            style={{ fontSize: "1.8em", color: "#3d5170", cursor: "pointer" }}
            onClick={() => history.goBack()}
          ></i>

          <PageTitle
            sm="4"
            title="Change Webhook URL"
            className="text-sm-left"
          />
        </Row>

        <Row className="px-2">
          <Col lg="6">
            <Card small className="mb-4">
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  {/* New Webhook URL */}
                  <FormGroup>
                    <label htmlFor="newWebhookUrl">New Webhook URL</label>
                    <FormInput
                      type="text"
                      id="newWebhookUrl"
                      placeholder="https://my-api-webhook.com/recieve_payload"
                      value={newWebhookUrl}
                      onChange={(e) => setNewWebhookUrl(e.target.value)}
                      invalid={errors.newWebhookUrl.length > 0}
                    />
                    {errors.newWebhookUrl && (
                      <FormFeedback>{errors.newWebhookUrl}</FormFeedback>
                    )}
                  </FormGroup>

                  <FormGroup className="pt-3">
                    <Button type="submit">
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Change Webhook URL"
                      )}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChangeWebhook;
