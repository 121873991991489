import { combineReducers } from "redux";

import * as candidatesReducer from "../views/Candidates/reducer";
import * as candidateDetailReducer from "../views/CandidateDetail/reducer";
import * as analyticsReducer from "../views/Analytics/reducer";
import * as loginReducer from "../views/Login/reducer";
import * as createCheckReducer from "../views/CreateCheck/reducer";
import * as settingsReducer from "../views/Settings/reducer";
import * as userInfoReducer from "../layouts/reducer";

export default combineReducers(
  Object.assign(
    {},
    candidatesReducer,
    candidateDetailReducer,
    analyticsReducer,
    loginReducer,
    createCheckReducer,
    settingsReducer,
    userInfoReducer
  )
);
