import {
  invalidEmail,
  invalidPhoneNumber,
  invalidText,
  isAllTrue,
} from "./helper";

export const createCheckValidation = (
  name,
  designation,
  department,
  email,
  phoneNumber,
  fatherName,
  address,
  aadhar,
  driving
) => {
  let errors = {};

  // name
  if (name !== null)
    if (invalidText(name)) errors.name = invalidText(name, "name");
    else errors.name = "";

  // designation
  if (designation !== null)
    if (invalidText(designation))
      errors.designation = invalidText(designation, "designation");
    else errors.designation = "";
  // department
  if (department !== null)
    if (invalidText(department))
      errors.department = invalidText(department, "department");
    else errors.department = "";
  // email
  if (email !== null)
    if (invalidEmail(email)) errors.email = invalidEmail(email);
    else errors.email = "";
  // phoneNumber
  if (phoneNumber !== null)
    if (invalidPhoneNumber(phoneNumber))
      errors.phoneNumber = invalidPhoneNumber(phoneNumber);
    else errors.phoneNumber = "";
  // Fathername
  if (fatherName !== null)
    if (invalidText(fatherName))
      errors.fatherName = invalidText(fatherName, "father's name");
    else errors.fatherName = "";
  // address
  if (address !== null)
    if (invalidText(address)) errors.address = invalidText(address, "address");
    else errors.address = "";

  // Aadhar
  if (aadhar !== null)
    if (!aadhar) errors.aadhar = "Please upload aadhar image";
    else errors.aadhar = "";

  // driving
  if (driving !== null)
    if (!driving) errors.driving = "Please upload driving image";
    else errors.driving = "";

  if (!isAllTrue(Object.values(errors))) {
    return errors;
  } else {
    errors = "";
    return errors;
  }
};
