import { put, takeLatest, call } from "redux-saga/effects";
import {
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_ACTION,
  LOGIN_ERROR,
  LOGIN_LOADING,
} from "./types";
import { loginApi, forgotPasswordApi } from "./api";
import { Constant } from "../../lib/Constants";
import { delay } from "../../lib/helper";

function* loginSaga(data) {
  let errorFlag = false;
  yield put({ type: LOGIN_LOADING, payload: true });
  try {
    const { history } = data.payload;
    delete data.payload.history;

    let res = yield call(loginApi, data.payload);

    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({ type: LOGIN_LOADING, payload: false });
      window.$crisp.push(["set", "user:email", [data.payload.email]]);
      yield localStorage.setItem("token", res.token);
      history.push("/");
    } else {
      errorFlag = true;
      yield put({ type: LOGIN_ERROR, payload: res.error });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: LOGIN_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: LOGIN_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}
function* forgotPasswordSaga(data) {
  yield put({ type: FORGOT_PASSWORD_LOADING, payload: true });
  try {
    delete data.payload.history;

    let res = yield call(forgotPasswordApi, data.payload);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: "Mail sent",
      });
    } else {
      yield put({
        type: FORGOT_PASSWORD_ERROR,
        payload: res.error_message,
      });
    }
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_ERROR, payload: error.toString() });
  } finally {
    yield call(delay);
    yield put({ type: FORGOT_PASSWORD_ERROR, payload: "" });
  }
}

function* watchLoginSaga() {
  yield takeLatest(LOGIN_ACTION, loginSaga);
  yield takeLatest(FORGOT_PASSWORD_ACTION, forgotPasswordSaga);
}
export default watchLoginSaga;
