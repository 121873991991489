import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "shards-react";

function Permissions({ permissionsData }) {
  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Permissions</h6>
      </CardHeader>
      {/* all permissions data */}
      <CardBody>
        {permissionsData
          ? permissionsData.map((permission, i) => (
              <Row className="mb-4" key={i}>
                <Col>
                  <span>{permission.candidate_group_name}</span>
                </Col>
                {permission.permissions ? (
                  permission.permissions.map((data, i) => (
                    <Col key={i}>
                      <span
                        className={
                          data.allowed ? "text-success" : "text-danger"
                        }
                        style={{ cursor: "default" }}
                      >
                        {data.allowed ? (
                          <span className="text-success">&#10003; </span>
                        ) : (
                          <span className="text-danger">&#10005; </span>
                        )}
                        {data.permission_text}
                      </span>
                    </Col>
                  ))
                ) : (
                  <span className="mr-4">No permissions</span>
                )}
              </Row>
            ))
          : null}
      </CardBody>
    </Card>
  );
}

export default Permissions;
