import coreApi from "../../lib/coreApi";

export let changePasswordApi = (data) => {
  let url = "current_user";
  let result = coreApi.PATCH(url, data);
  return result;
};

export let getOrganizationUsersApi = () => {
  let url = "org_users";
  let result = coreApi.GET(url);
  return result;
};

export let changePermissionApi = (data) => {
  let url = "org_user_permissions";
  let result = coreApi.PATCH(url, data);
  return result;
};

export let getActivityApi = (data) => {
  let url = `org_user_activity?user_id=${data}`;
  let result = coreApi.GET(url);
  return result;
};

export let inviteUserApi = (data) => {
  let url = "org_users";
  let result = coreApi.POST(url, data);
  return result;
};

export let changeEmailNotificationApi = (data) => {
  let url = "current_user";
  let result = coreApi.PATCH(url, data);
  return result;
};
