import {
  CHANGE_PASSWORD_ACTION,
  CHANGE_PERMISSION_ACTION,
  GET_ORG_USERS_ACTION,
  GET_ACTIVITY_ACTION,
  INVITE_USER_ACTION,
  EMAIL_NOTIFICATION_ACTION,
} from "./types";

export const changePasswordAction = (data) => ({
  type: CHANGE_PASSWORD_ACTION,
  payload: data,
});

export const getOrgUsersAction = () => ({
  type: GET_ORG_USERS_ACTION,
});

export const changePermissionAction = (data) => ({
  type: CHANGE_PERMISSION_ACTION,
  payload: data,
});

export const getActivityAction = (data) => ({
  type: GET_ACTIVITY_ACTION,
  payload: data,
});

export const inviteNewUserAction = (data) => ({
  type: INVITE_USER_ACTION,
  payload: data,
});

export const emailNotificationAction = (data) => ({
  type: EMAIL_NOTIFICATION_ACTION,
  payload: data,
});
