import coreApi from "../lib/coreApi";

export const getUserInfoApi = () => {
  let url = "current_user";
  let result = coreApi.GET(url);
  return result;
};

export const getNotificationApi = () => {
  let url = "notifications";
  let result = coreApi.GET(url);
  return result;
};

export const clearNotificationApi = () => {
  let url = "notifications";
  let result = coreApi.PATCH(url);
  return result;
};

export const getGroupList = () => {
  let url = "candidate_groups";
  let result = coreApi.GET(url);
  return result;
};

export const createGroupApi = (data) => {
  let url = "candidate_groups";
  let result = coreApi.POST(url, data);
  return result;
};
