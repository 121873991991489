import {
  UPDATE_CANDIDATE_GROUP_ACTION,
  CANDIDATES_BY_GROUP_ACTION,
  UPDATE_MONITORING_ACTION,
  SUPPORT_ACTION,
  EXPORT_CHECKS_ACTION,
  EXPORT_DOWNLOAD_ZIP_ACTION,
} from "./types";

export const updateCandidateGroup = (data) => ({
  type: UPDATE_CANDIDATE_GROUP_ACTION,
  payload: data,
});

export const candidatesByGroupAction = (data) => ({
  type: CANDIDATES_BY_GROUP_ACTION,
  payload: data,
});

export const updateMonitoringAction = (data) => ({
  type: UPDATE_MONITORING_ACTION,
  payload: data,
});

export const supportAction = (data) => ({
  type: SUPPORT_ACTION,
  payload: data,
});

export const exportChecksAction = (data) => ({
  type: EXPORT_CHECKS_ACTION,
  payload: data,
});
export const exportDownloadZipAction = (data) => ({
  type: EXPORT_DOWNLOAD_ZIP_ACTION,
  payload: data,
});
