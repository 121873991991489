import createReducer from "../lib/createReducer";
import {
  GET_USER_INFO_LOADING,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  CLEAR_NOTIFICATION_SUCCESS,
  CLEAR_NOTIFICATION_ERROR,
  GROUP_LIST_LOADING,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_ERROR,
  CREATE_GROUP_LOADING,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
} from "./types";

let initialState = {
  userInfoLoading: false,
  grouplistLoading: false,
  createGroupLoading: false,
  userinfoError: "",
  notificationError: "",
  grouplistError: "",
  createGroupError: "",
  userInfo: {},
  notifications: [],
  groupList: [],
  createGroupSuccess: "",
};

export const userInfoReducer = createReducer(initialState, {
  // GET USER INFO
  [GET_USER_INFO_LOADING](state, action) {
    return Object.assign({}, state, {
      userInfoLoading: action.payload,
      userinfoError: "",
    });
  },
  [GET_USER_INFO_SUCCESS](state, action) {
    return Object.assign({}, state, {
      userInfoLoading: false,
      userInfo: action.payload,
      userinfoError: "",
    });
  },
  [GET_USER_INFO_ERROR](state, action) {
    return Object.assign({}, state, {
      userInfoLoading: false,
      userInfo: {},
      userinfoError: action.payload,
    });
  },

  // GET NOTIFICATION
  [GET_NOTIFICATION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      notifications: action.payload,
      notificationError: "",
    });
  },
  [GET_NOTIFICATION_ERROR](state, action) {
    return Object.assign({}, state, {
      notifications: [],
      notificationError: action.payload,
    });
  },

  // CLEAR NOTIFICATION
  [CLEAR_NOTIFICATION_SUCCESS](state) {
    return Object.assign({}, state, {
      notifications: [],
      notificationError: "",
    });
  },
  [CLEAR_NOTIFICATION_ERROR](state, action) {
    return Object.assign({}, state, {
      notificationError: action.payload,
    });
  },

  // GROUP LIST
  [GROUP_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      grouplistLoading: action.payload,
      grouplistError: "",
    });
  },
  [GROUP_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      grouplistLoading: false,
      groupList: action.payload,
      grouplistError: "",
    });
  },
  [GROUP_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      grouplistLoading: false,
      groupList: [],
      grouplistError: action.payload,
    });
  },

  // CREATE GROUP
  [CREATE_GROUP_LOADING](state, action) {
    return Object.assign({}, state, {
      createGroupLoading: action.payload,
      createGroupSuccess: "",
      createGroupError: "",
    });
  },
  [CREATE_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      createGroupLoading: false,
      createGroupSuccess: action.payload,
      createGroupError: "",
    });
  },
  [CREATE_GROUP_ERROR](state, action) {
    return Object.assign({}, state, {
      createGroupLoading: false,
      createGroupSuccess: "",
      createGroupError: action.payload,
    });
  },
});
