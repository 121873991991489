import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Login, ForgotPassword } from "./views";
import AppRoutes from "./routes";
import { DefaultLayout } from "./layouts";

import { Provider } from "react-redux";
import store from "./store";

const Router = (props) => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route
              path="/"
              render={(props) =>
                localStorage.getItem("token") ? (
                  <Switch>
                    <DefaultLayout>
                      {AppRoutes.map((route, index) => {
                        return (
                          <Route
                            key={index}
                            path={route.path}
                            exact={true}
                            component={(props) => {
                              return <route.component {...props} />;
                            }}
                          />
                        );
                      })}
                    </DefaultLayout>
                  </Switch>
                ) : (
                  <Login {...props} />
                )
              }
            />
          </Switch>
        </BrowserRouter>
      </Provider>
    </>
  );
};
export default Router;
