export const GET_CHART_ACTION = "GET_CHART_ACTION";
export const GET_CHART_LOADING = "GET_CHART_LOADING";
export const GET_CHART_ERROR = "GET_CHART_ERROR";
export const SMALL_CHART_SUCCESS = "SMALL_CHART_SUCCESS";
export const LINE_CHART_SUCCESS = "LINE_CHART_SUCCESS";
export const PIE_CHART_SUCCESS = "PIE_CHART_SUCCESS";
export const PIE_CHART_LIST_SUCCESS = "PIE_CHART_LIST_SUCCESS";

export const GET_PIE_DATA_ACTION = "GET_PIE_DATA_ACTION";
export const GET_PIE_DATA_SUCCESS = "GET_PIE_DATA_SUCCESS";
export const GET_PIE_DATA_ERROR = "GET_PIE_DATA_ERROR";
