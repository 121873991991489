import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_SPECIFIC_CHECK_ACTION,
  GET_SPECIFIC_CHECK_ERROR,
  GET_SPECIFIC_CHECK_LOADING,
  GET_SPECIFIC_CHECK_SUCCESS,
} from "./types";
import { getSpecificCheckApi } from "./api";
import { Constant } from "../../lib/Constants";
import { delay } from "../../lib/helper";

function* getSpecificCheckSaga(data) {
  let errorFlag = false;
  yield put({ type: GET_SPECIFIC_CHECK_LOADING, payload: true });
  try {
    let res = yield call(getSpecificCheckApi, data.payload);
    if (
      res.status.toUpperCase() ===
      Constant.RESPONSE_STATUS.SUCCESS.toUpperCase()
    ) {
      delete res.status;
      yield put({ type: GET_SPECIFIC_CHECK_SUCCESS, payload: res });
    } else {
      errorFlag = true;
      yield put({ type: GET_SPECIFIC_CHECK_ERROR, payload: res.error_message });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: GET_SPECIFIC_CHECK_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: GET_SPECIFIC_CHECK_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* watchGetSpecificCheckSaga() {
  yield takeLatest(GET_SPECIFIC_CHECK_ACTION, getSpecificCheckSaga);
}
export default watchGetSpecificCheckSaga;
