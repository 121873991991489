export const Constant = {
  BASE_URL: "https://backend.traqcheck.com/homepage/",
  // BASE_URL: 'http://localhost:8000/homepage/',
  REFRESH_CANDIDATE_TIME: 90000,

  RESPONSE_STATUS: {
    SUCCESS: "success",
    FAILED: "failed",
    MESSAGE: "error_message",
  },

  CANDIDATE_STATUS: {
    PENDING: "pending",
    COMPLETED: "completed",
    DISCREPANCY_FOUND: "discrepancy_found",
    ON_HOLD: "on_hold",
    INSUFFICIENT: "insufficient",
    AMBER: "amber",
    CASE_STOPPED: "case_stopped",
    QC_REJECTED: "pending"
  },

  CHART_TYPES: {
    SMALL_CHART: "small_chart",
    LINE_CHART: "line_chart",
    PIE_CHART: "pie_chart",
  },

  ZIP_FILE_ACTIONS: {
    REQUEST: "request",
    CHECK: "check",
    CONSUME: "consume",
  },
};
