import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { UserInfo, UserExtraInfo } from "../../components";
import {useHistory, useParams} from "react-router-dom";
import { Error, PageSpinner } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { getSpecificCheckAction } from "./action";

const CandidateDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, groupId } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getSpecificCheckAction(id));
    }
  }, [id, dispatch]);

  const { error, checkData, loading } = useSelector((state) => ({
    loading: state.candidateDetailReducer.loading,
    checkData: state.candidateDetailReducer.checkData,
    error: state.candidateDetailReducer.error,
  }));

  return (
    <>
      {loading ? (
        <PageSpinner />
      ) : (
        <>
          {error && <Error error message={error} />}
          <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
              <i
                className="fas fa-arrow-left mr-3"
                style={{
                  fontSize: "1.8em",
                  color: "#3d5170",
                  cursor: "pointer",
                }}
                onClick={() => history.goBack()}
              ></i>
              <PageTitle
                title="Candidate Details"
                sm="4"
                className="text-sm-left"
              />
            </Row>
            <Row>
              {checkData && Object.keys(checkData).length > 0 ? (
                <>
                  <Col lg="4">
                    <UserInfo userDetail={checkData} groupId={groupId} />
                  </Col>
                  <Col lg="8">
                    <UserExtraInfo userDetail={checkData} />
                  </Col>
                </>
              ) : null}
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default CandidateDetail;
