import { put, takeLatest, call } from "redux-saga/effects";
import {
  CREATE_CHECK_ACTION,
  CREATE_CHECK_ERROR,
  CREATE_CHECK_LOADING,
  CREATE_CHECK_SUCCESS,
  ESTIMATES_ACTION,
  ESTIMATES_LOADING,
  ESTIMATES_SUCCESS,
  ESTIMATES_ERROR,
} from "./types";
import { createCheckApi, getEstimatesForCheck } from "./api";
import { delay } from "../../lib/helper";
import { Constant } from "../../lib/Constants";

function* createCheckSaga(data) {
  yield put({ type: CREATE_CHECK_LOADING, payload: true });
  try {
    let res = yield call(createCheckApi, data.payload);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: CREATE_CHECK_SUCCESS,
        payload: "Check created successfully",
      });
    } else {
      yield put({
        type: CREATE_CHECK_ERROR,
        payload: res.error_message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_CHECK_ERROR, payload: error.toString() });
  } finally {
    yield call(delay);
    yield put({ type: CREATE_CHECK_ERROR, payload: "" });
  }
}

function* getEstimatesSaga(data) {
  let errorFlag = false;
  yield put({ type: ESTIMATES_LOADING, payload: true });
  try {
    let res = yield call(getEstimatesForCheck, data);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      delete res.status;
      yield put({ type: ESTIMATES_SUCCESS, payload: res });
    } else {
      errorFlag = true;
      yield put({ type: ESTIMATES_ERROR, payload: res.error_message });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: ESTIMATES_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: ESTIMATES_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* watchCreateCheckSaga() {
  yield takeLatest(CREATE_CHECK_ACTION, createCheckSaga);
  yield takeLatest(ESTIMATES_ACTION, getEstimatesSaga);
}

export default watchCreateCheckSaga;
