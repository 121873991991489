export const CHANGE_PASSWORD_ACTION = "CHANGE_PASSWORD_ACTION";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";

export const GET_ORG_USERS_ACTION = "GET_ORG_USERS_ACTION";
export const GET_ORG_USERS_LOADING = "GET_ORG_USERS_LOADING";
export const GET_ORG_USERS_SUCCESS = "GET_ORG_USERS_SUCCESS";
export const GET_ORG_USERS_ERROR = "GET_ORG_USERS_ERROR";

export const CHANGE_PERMISSION_ACTION = "CHANGE_PERMISSIONS_ACTION";
export const CHANGE_PERMISSION_SUCCESS = "CHANGE_PERMISSIONS_SUCCESS";
export const CHANGE_PERMISSION_ERROR = "CHANGE_PERMISSIONS_ERROR";
export const CHANGE_PERMISSION_LOADING = "CHANGE_PERMISSIONS_LOADING";

export const GET_ACTIVITY_ACTION = "GET_ACTIVITY_ACTION";
export const GET_ACTIVITY_LOADING = "GET_ACTIVITY_LOADING";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_ERROR = "GET_ACTIVITY_ERROR";

export const INVITE_USER_ACTION = "INVITE_USER_ACTION";
export const INVITE_USER_LOADING = "INVITE_USER_LOADING";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_ERROR = "INVITE_USER_ERROR";

export const EMAIL_NOTIFICATION_ACTION = "EMAIL_NOTIFICATION_ACTION";
export const EMAIL_NOTIFICATION_LOADING = "EMAIL_NOTIFICATION_LOADING";
export const EMAIL_NOTIFICATION_ERROR = "EMAIL_NOTIFICATION_ERROR";
