import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  FormTextarea,
  Button,
} from "shards-react";
import { Spinner } from "react-bootstrap";
import { Error } from "../index";
import { useSelector, useDispatch } from "react-redux";
import { supportAction } from "../../views/Candidates/action";

const SupportModal = ({ open, toggle, checkId }) => {
  const dispatch = useDispatch();

  // local state
  const [issue, setIssue] = useState("");

  // redux state
  const { supportLoading, supportError, supportSuccess } = useSelector(
    (state) => ({
      supportLoading: state.candidatesReducer.supportLoading,
      supportError: state.candidatesReducer.supportError,
      supportSuccess: state.candidatesReducer.supportSuccess,
    })
  );

  // success effect
  useEffect(() => {
    if (supportSuccess) {
      setIssue("");
      setTimeout(() => {
        toggle();
      }, 3000);
    }
    // eslint-disable-next-line
  }, [supportSuccess]);

  const handleIssueSubmit = (e) => {
    e.preventDefault();
    let dataToSend = {
      issue_content: issue,
      check_id: checkId,
    };
    dispatch(supportAction(dataToSend));
  };

  return (
    <Modal open={open} toggle={() => toggle()} centered>
      <ModalHeader>
        Just describe your issue below and the TraqCheck team resolve it ASAP!
      </ModalHeader>
      <div>
        {supportError && <Error error message={supportError} />}
        {supportSuccess && <Error success message={supportSuccess} />}
      </div>
      <ModalBody>
        <Form onSubmit={handleIssueSubmit}>
          <FormGroup>
            <FormTextarea
              name="issue"
              placeholder="Please enter your issue"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
              row="5"
              style={{ resize: "none" }}
            />
          </FormGroup>
          <FormGroup>
            <Button theme="primary" type="submit" disabled={!issue}>
              {supportLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Submit your issue"
              )}
            </Button>
            <Button
              theme="danger"
              style={{ marginLeft: 15 }}
              onClick={() => {
                toggle();
                setIssue("");
              }}
            >
              Cancel
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default SupportModal;
