import createReducer from "../../lib/createReducer";
import {
  UPDATE_CANDIDATE_GROUP_LOADING,
  UPDATE_CANDIDATE_GROUP_SUCCESS,
  UPDATE_CANDIDATE_GROUP_ERROR,
  CANDIDATES_BY_GROUP_LOADING,
  CANDIDATES_BY_GROUP_SUCCESS,
  CANDIDATES_BY_GROUP_ERROR,
  UPDATE_MONITORING_LOADING,
  UPDATE_MONITORING_SUCCESS,
  UPDATE_MONITORING_ERROR,
  SUPPORT_LOADING,
  SUPPORT_SUCCESS,
  SUPPORT_ERROR,
  UPDATE_EXPORT_CHECK_STATE,
  UPDATE_EXPORT_CHECK_STATE_ERROR,
} from "./types";
import { cloneDeep, findIndex, isArray } from "lodash";

let initialState = {
  error: "",
  loading: false,
  candidatesData: [],
  pages: [],
  success: "",
  monitoringLoading: false,
  monitoringSuccess: "",

  // support
  supportLoading: false,
  supportError: "",
  supportSuccess: "",

  exportCheckState: [],
};

export const candidatesReducer = createReducer(initialState, {
  // UPDATE CANDIDATE GROUP
  [UPDATE_CANDIDATE_GROUP_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
      error: "",
    });
  },
  [UPDATE_CANDIDATE_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: "",
    });
  },
  [UPDATE_CANDIDATE_GROUP_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: "",
      error: action.payload,
    });
  },

  // CANDIDATES BY GROUP
  [CANDIDATES_BY_GROUP_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
      error: "",
    });
  },
  [CANDIDATES_BY_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      candidatesData: action.payload,
      pages: action.pages,
      error: "",
    });
  },
  [CANDIDATES_BY_GROUP_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      candidatesData: [],
      error: action.payload,
    });
  },

  // UPDATE MONITORING
  [UPDATE_MONITORING_LOADING](state, action) {
    return Object.assign({}, state, {
      monitoringLoading: action.payload,
      monitoringSuccess: "",
      error: "",
    });
  },
  [UPDATE_MONITORING_SUCCESS](state, action) {
    return Object.assign({}, state, {
      monitoringLoading: false,
      monitoringSuccess: action.payload,
      error: "",
    });
  },
  [UPDATE_MONITORING_ERROR](state, action) {
    return Object.assign({}, state, {
      monitoringLoading: false,
      monitoringSuccess: "",
      error: action.payload,
    });
  },

  // SUPPORT
  [SUPPORT_LOADING](state, action) {
    return Object.assign({}, state, {
      supportLoading: action.payload,
      supportSuccess: "",
      supportError: "",
    });
  },
  [SUPPORT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      supportLoading: false,
      supportSuccess: action.payload,
      supportError: "",
    });
  },
  [SUPPORT_ERROR](state, action) {
    return Object.assign({}, state, {
      supportLoading: false,
      supportSuccess: "",
      supportError: action.payload,
    });
  },
  [UPDATE_EXPORT_CHECK_STATE](state, action) {
    let newExportCheckState = cloneDeep(state.exportCheckState);
    if (newExportCheckState?.length > 0) {
      let index = findIndex(
        newExportCheckState,
        (x) => x.groupId === action.payload.groupId
      );
      if (index >= 0) {
        newExportCheckState.splice(index, 1, action.payload);
      } else {
        newExportCheckState.push(action.payload);
      }
    } else if (isArray(action.payload)) {
      newExportCheckState = action.payload;
    } else newExportCheckState.push(action.payload);
    localStorage.setItem(
      "group_zip_state",
      JSON.stringify(newExportCheckState)
    );
    return Object.assign({}, state, {
      exportCheckState: newExportCheckState,
    });
  },
  [UPDATE_EXPORT_CHECK_STATE_ERROR](state, action) {
    return Object.assign({}, state, {
      error: action.payload,
    });
  },
});
