import "./style.css";
import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { UserCard, PageSpinner, Error } from "../../components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FormInput } from "shards-react";
import { useSelector, useDispatch } from "react-redux";
import {
  candidatesByGroupAction,
} from "./action";
import { Constant } from "../../lib/Constants";
import classNames from "classnames";
import { find, uniq, pull } from "lodash";
import { Pagination } from "react-bootstrap";
import { checkStatus } from "../../lib/dummyData";

// const exportStateData = [
//   { text: "Export", class: "primary" },
//   { text: "Generating", class: "secondary" },
//   { text: "Download", class: "success" },
// ];

const Candidates = () => {
  let history = useHistory();
  let location = useLocation();
  let dispatch = useDispatch();
  let { groupId } = useParams();

  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [search, setSearch] = useState("");
  const [zipState, setZipState] = useState({ groupId, state: 0 });

  // Filters for checks
  const [filters, setFilters] = useState([]);

  let {
    error,
    loading,
    candidatesData,
    exportCheckState,
    checkdata,
    groupList,
    pages,
  } = useSelector((state) => ({
    error: state.candidatesReducer.error,
    loading: state.candidatesReducer.loading,
    candidatesData: state.candidatesReducer.candidatesData,
    exportCheckState: state.candidatesReducer.exportCheckState,
    checkdata: state.candidatesReducer.checkdata,
    groupList: state.userInfoReducer.groupList,
    pages: state.candidatesReducer.pages,
  }));

  useEffect(() => {
    if (exportCheckState.length > 0) {
      let data = find(exportCheckState, (x) => x.groupId === groupId);
      if (data) setZipState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportCheckState]);

  useEffect(() => {
    if (groupId) {
      // Instead of wasting time to check whether
      // things need refresh, just refresh
      let intervalRef = setInterval(
        apiCall,
        Constant.REFRESH_CANDIDATE_TIME
      );
      return () => clearInterval(intervalRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const apiCall = useCallback((withoutLoading = true) => {
    if (groupId) {
      const statuses = filters;
      dispatch(candidatesByGroupAction({ candidateGroupId: groupId, withoutLoading, search, statuses, page }));
    } else {
      if (groupList && groupList.length > 0) {
        history.push(`${location.pathname}${groupList[0].candidate_group_id}`);
      } else {
        history.push("/newGroup");
      }
    }
  }, [dispatch, groupId, groupList, history, location, search,
    filters, page]);

  useEffect(() => {
    apiCall(false);
  }, [apiCall, groupList]);

  const callGroupList = useCallback(() => {
    if (groupList && groupList.length > 0 && groupId) {
      let x = groupList.find(
        (item) => item.candidate_group_id.toString() === groupId.toString()
      ).candidate_group_name;
      setName(x);
    }
  }, [groupList, groupId]);
  useEffect(() => callGroupList(), [callGroupList]);

  useEffect(() => {
    if (checkdata && checkdata.check_id) {
      history.push({
        pathname: `${location.pathname}/${checkdata.check_id}`,
        state: { user: checkdata, groupId: groupId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkdata]);

  /*
  useEffect(() => {
    if (candidatesData && candidatesData.length > 0) {
      let data = filterDataByButtons(candidatesData);
      if (search !== "" && search.length > 0)
        setUsersData(
          data.filter((f) => {
            return f.candidate_details.candidate_name
              .toLowerCase()
              .includes(search.toLowerCase());
          })
        );
      else if (discrepanciesFilter || pendingFilter || traqCheckedFilter)
        setUsersData(data);
      else setUsersData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidatesData,
    search,
    discrepanciesFilter,
    pendingFilter,
    traqCheckedFilter,
  ]);
   */

  const handleClick = (user) => {
    history.push({
      pathname: `${location.pathname}/${user.check_id}`,
      state: { checkId: user.check_id, groupId },
    });
  };

  /*
   * Handle export feature is disabled by Nitesh, because it doesn't work
  const handleOnExport = () => {
    if (zipState.state === 0) {
      dispatch(exportChecksAction(groupId));
    } else if (zipState.state === 2) {
      dispatch(exportDownloadZipAction({ groupId, groupName: name }));
    }
  };
   */

  const handleFilterChange = (filter) => {
    setFilters(prevState => {
      const state = [...prevState];
      if (prevState.includes(filter)) {
        pull(state, filter);
        return state;
      } else {
        return uniq([
          ...prevState,
          filter
        ]);
      }
    });
  };

  const CheckPagination = ({ hideResults }) => {
    return pages && candidatesData && candidatesData.length ? (
        <Col xs={12} style={{alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
          <Pagination>
            {pages.slice(0, 10).map(x => (
              <Pagination.Item onClick={() => setPage(x)}>{x}</Pagination.Item>
            ))}
          </Pagination>
          {!hideResults ? <p>Showing {candidatesData.length} results</p> : null}
        </Col>
      ) : null;
  };

  const resetPage = () => setPage(1);

  return (
    <>
        <>
          {error && <Error error message={error} />}
          <Container fluid className="main-content-container px-4 pb-4">
            {/* Page Header */}
            <Row
              noGutters
              className="page-header py-4"
              style={{ justifyContent: "space-between" }}
            >
              <PageTitle
                sm={12}
                md={3}
                lg={4}
                title={name}
                className="text-sm-left my-2"
              />

              <Col xs={12} sm={12} md={6} lg={5} className="my-2">
                <div
                  className="d-flex flex-row justify-content-end"
                  style={{ marginRight: "10px", flexWrap: "wrap" }}
                >
                  {checkStatus.map((status) => {
                    return (
                      <button
                        onClick={() => {
                          handleFilterChange(status.name);
                          resetPage();
                        }}
                        className={classNames("mx-2 mb-2 filter-button", {
                          "filter-button-active": filters.includes(status.name),
                        })}
                      >
                        {status.humanReadable}
                      </button>
                    )
                  })}

                </div>
              </Col>
              <Col xs={12} sm={12} md={3} className="my-2">
                <div className="d-flex flex-row">
                  <FormInput
                    placeholder="Search for candidates"
                    onChange={(e) => {
                      resetPage();
                      setSearch(e.target.value);
                    }}
                    value={search}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <CheckPagination />
              {loading ? (
                <PageSpinner />
              ) : candidatesData && candidatesData.length > 0 ? (
                candidatesData.map((user, i) => {
                  return (
                    <Col lg={3} md={6} sm={12} className="mb-4" key={i}>
                      <UserCard user={user} handleClick={handleClick} />
                    </Col>
                  );
                })
              ) : (
                <span className="m-auto">No candidates</span>
              )}
              <CheckPagination hideResults />
            </Row>
          </Container>
        </>
    </>
  );
};

export default Candidates;
