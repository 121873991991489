import React from "react";
import { Alert } from "shards-react";

const Error = ({ error, success, message = "No message" }) => {
  let theme = "primary";
  let icon = <i className="fa fa-info mx-2"></i>;

  if (error) {
    theme = "danger";
    icon = <i className="fas fa-exclamation-triangle"></i>;
  }
  if (success) {
    theme = "success";
    icon = <i className="fa fa-check" aria-hidden="true"></i>;
  }

  return (
    <Alert className="mb-1" theme={theme}>
      {icon}
      <span style={{ marginLeft: 10, color: "#fff" }}> {message}</span>
    </Alert>
  );
};

export default Error;
