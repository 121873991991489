import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
} from "shards-react";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Error } from "../../components";
import { createGroupAction } from "../../layouts/action";

const NewGroup = () => {
  const dispatch = useDispatch();

  const [groupName, setGroupName] = useState("");

  const {
    createGroupError,
    createGroupLoading,
    createGroupSuccess,
  } = useSelector((state) => ({
    createGroupLoading: state.userInfoReducer.createGroupLoading,
    createGroupError: state.userInfoReducer.createGroupError,
    createGroupSuccess: state.userInfoReducer.createGroupSuccess,
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    let dataTosend = {
      candidate_group_name: groupName,
    };
    dispatch(createGroupAction(dataTosend));
  };

  return (
    <>
      {createGroupError && <Error error message={createGroupError} />}
      {createGroupSuccess && <Error success message={createGroupSuccess} />}
      <Container fluid className="main-content-container pb-4 p-4">
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Card>
              <CardHeader className="border-bottom">
                <i className="material-icons">add</i> Create new group
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <label htmlFor="groupname">Enter group name</label>
                    <FormInput
                      id="groupname"
                      placeholder="Group name"
                      onChange={(e) => setGroupName(e.target.value)}
                      value={groupName}
                    />
                  </FormGroup>
                  <Button type="submit" disabled={!groupName} className="mt-2">
                    {createGroupLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Create group"
                    )}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewGroup;
