import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import { Error, PageSpinner } from "../components";
import { getUserInfo, getNotification, getGroupList } from "./action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  EXPORT_CHECK_API_ACTION,
  UPDATE_EXPORT_CHECK_STATE,
} from "../views/Candidates/types";

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    userInfoLoading,
    grouplistLoading,
    userinfoError,
    notificationError,
    grouplistError,
  } = useSelector((state) => ({
    userInfoLoading: state.userInfoReducer.userInfoLoading,
    grouplistLoading: state.userInfoReducer.grouplistLoading,
    userinfoError: state.userInfoReducer.userinfoError,
    notificationError: state.userInfoReducer.notificationError,
    grouplistError: state.userInfoReducer.grouplistError,
  }));

  const apiCall = useCallback(() => {
    dispatch(getUserInfo());
    dispatch(getNotification());
    dispatch(getGroupList());
  }, [dispatch]);
  useEffect(() => {
    apiCall();
  }, [apiCall]);

  useEffect(() => {
    let localStorageGroupState = localStorage.getItem("group_zip_state");
    if (localStorageGroupState) {
      let exportCheckLocalData = JSON.parse(localStorageGroupState);
      if (exportCheckLocalData?.length > 0) {
        dispatch({
          type: UPDATE_EXPORT_CHECK_STATE,
          payload: exportCheckLocalData,
        });
        exportCheckLocalData.forEach((x) => {
          if (x.state === 1) {
            dispatch({ type: EXPORT_CHECK_API_ACTION, payload: x.groupId });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Container fluid>
      <Row>
        <MainSidebar />
        <Col
          className="main-content p-0"
          lg={{ size: 10, offset: 2 }}
          md={{ size: 9, offset: 3 }}
          sm="12"
          tag="main"
        >
          {!noNavbar && <MainNavbar />}
          {userInfoLoading || grouplistLoading ? (
            <PageSpinner />
          ) : (
            <>
              {userinfoError || notificationError || grouplistError ? (
                <Error
                  error
                  message={userinfoError || notificationError || grouplistError}
                />
              ) : null}
              {children}
            </>
          )}
          {!noFooter && <MainFooter />}
        </Col>
      </Row>
    </Container>
  );
};

DefaultLayout.propTypes = {
  noNavbar: PropTypes.bool,
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
