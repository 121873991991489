import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import {
  Error,
  PageSpinner,
  LineChart,
  SmallChart,
  PieChart,
} from "../../components";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getChartsAction } from "./action";

const Analytics = () => {
  const dispatch = useDispatch();

  const {
    error,
    loading,
    smallChartData,
    lineChartData,
    pieChartData,
  } = useSelector((state) => ({
    loading: state.analyticsReducer.loading,
    error: state.analyticsReducer.error,
    smallChartData: state.analyticsReducer.smallChartData,
    lineChartData: state.analyticsReducer.lineChartData,
    pieChartData: state.analyticsReducer.pieChartData,
  }));

  // INITIAL EFFECT
  useEffect(() => {
    dispatch(getChartsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <PageSpinner />
      ) : (
        <>
          {error && <Error error message={error} />}
          <Container fluid className="main-content-container px-4 pb-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Analytics" className="text-sm-left" />
            </Row>

            {/* SMALL CHARTS */}
            <Row>
              {smallChartData && smallChartData.length > 0
                ? smallChartData.map((chart, i) => (
                    <Col className="col-lg mb-4" lg="3" md="6" sm="12" key={i}>
                      <SmallChart data={chart} index={i} />
                    </Col>
                  ))
                : null}
            </Row>

            {/* LINE CHART */}
            <Row>
              {lineChartData && lineChartData.length > 0
                ? lineChartData.map((chart, i) => (
                    <Col lg="6" md="12" sm="12" className="mb-4" key={i}>
                      <LineChart data={chart} />
                    </Col>
                  ))
                : null}
            </Row>

            {/* PIE CHART */}
            <Row>
              {pieChartData ? (
                <Col lg="8" md="12" sm="12" className="mb-4">
                  <PieChart />
                </Col>
              ) : null}
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Analytics;
