import createReducer from "../../lib/createReducer";
import {
  CREATE_CHECK_LOADING,
  CREATE_CHECK_ERROR,
  CREATE_CHECK_SUCCESS,
  ESTIMATES_LOADING,
  ESTIMATES_SUCCESS,
  ESTIMATES_ERROR,
} from "./types";

let initialState = {
  error: "",
  loading: false,
  success: "",
  estimateData: {},
  estimateLoading: false,
};

export const createCheckReducer = createReducer(initialState, {
  // CREATE CHECK
  [CREATE_CHECK_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
      success: "",
      error: "",
    });
  },
  [CREATE_CHECK_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: "",
    });
  },
  [CREATE_CHECK_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: "",
      error: action.payload,
    });
  },

  // ESTIMATES
  [ESTIMATES_LOADING](state, action) {
    return Object.assign({}, state, {
      estimateLoading: action.payload,
      error: "",
    });
  },
  [ESTIMATES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      estimateLoading: false,
      estimateData: action.payload,
      error: "",
    });
  },
  [ESTIMATES_ERROR](state, action) {
    return Object.assign({}, state, {
      estimateLoading: false,
      error: action.payload,
    });
  },
});
