import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
} from "shards-react";
import { Spinner } from "react-bootstrap";
import {
  support,
  download,
  monitoring,
} from "../../assets/candidateDetail";
import { SupportModal } from "../index";

const MonitoringCard = ({
  userDetail,
  createCheck,
  monitoringValue,
  monitoringChange,
  loading,
}) => {
  const [supportModal, setSupportModal] = useState(false);

  const handleDownload = () => {
    window.open(userDetail.pdf_report_link);
  };

  const handleSupport = () => {
    setSupportModal(true);
  };

  return (
    <>
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0 blackText">Continuous Monitoring</h6>
        </CardHeader>
        {/* MONITORING SWITCH */}
        <CardBody
          className="border-bottom"
          style={{ paddingBottom: 30, paddingTop: 30 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 0.3,
                justifyContent: "center",
              }}
            >
              <img src={monitoring} alt="monitoringIcon" className="img120" />
            </div>
            <div style={{ flex: 0.7 }}>
              <span>
                Get realtime notifications about the candidate's criminal status
                and social media content
              </span>
              <br />
              <div style={{ marginTop: "10px" }}>
                Powered by{" "}
                <span style={{ fontWeight: "bolder", color: "#000" }}>
                  TraqCheck ID
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                  }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <>
                      <span>{monitoringValue ? "on" : "off"}</span>
                      <div style={{ marginLeft: "20px" }}>
                        <FormCheckbox
                          toggle
                          checked={monitoringValue}
                          onChange={() => monitoringChange()}
                        ></FormCheckbox>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        {/* FOR CANDIDATE DETAIL */}
        {!createCheck && (
          <>
            {/* AI Comments */}
            <CardBody
              style={{ paddingBottom: 20, paddingTop: 20 }}
              className="border-bottom"
            >
              <span>TraqCheck AI Comments</span>
              <div className="text-center mt-4">
                <span
                  style={{ whiteSpace: "pre-line", fontSize: 14 }}
                  dangerouslySetInnerHTML={{
                    __html: userDetail.traqcheck_ai_comments,
                  }}
                ></span>
              </div>
            </CardBody>

            {/* DOWNLOAD AND SUPPORT */}
            <CardBody
              className="border-bottom"
              style={{ paddingBottom: 30, paddingTop: 30 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="centerimageandtext pointer"
                  onClick={handleDownload}
                >
                  <img src={download} alt="download" className="img50" />
                  <span className="imageText">Download PDF Report</span>
                </div>
                <div
                  className="centerimageandtext pointer"
                  onClick={handleSupport}
                >
                  <img src={support} alt="support" className="img50" />
                  <span className="imageText">Contact TraqCheck Support</span>
                </div>
              </div>
            </CardBody>
          </>
        )}
      </Card>
      {!createCheck && (
        <SupportModal
          open={supportModal}
          toggle={() => setSupportModal(!supportModal)}
          checkId={userDetail ? userDetail.check_id : null}
        />
      )}
    </>
  );
};

export default MonitoringCard;
