import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "shards-react";
import { useDispatch, useSelector } from "react-redux";
import { PageSpinner } from "../index";
import { updateCandidateGroup } from "../../views/Candidates/action";
import { checkStatus, candidateDefaultAvatar } from "../../lib/dummyData";

const UserInfo = ({ userDetail, groupId }) => {
  let { image, highlightColor } = checkStatus.find(
    (c) => c.name === userDetail.check_completed_status
  );

  let dispatch = useDispatch();

  const [selectedRole, setSelectedRole] = useState("");
  const [dropdown, setDropdown] = useState(false);

  const { groupList } = useSelector((state) => ({
    groupList: state.userInfoReducer.groupList,
  }));

  const { loading, success } = useSelector((state) => ({
    loading: state.candidatesReducer.loading,
    success: state.candidatesReducer.success,
  }));

  useEffect(() => {
    if (groupList.length > 0)
      setSelectedRole(
        groupList.find(
          (x) => x.candidate_group_id.toString() === groupId.toString()
        )
      );
    // eslint-disable-next-line
  }, [groupList]);

  const apiClear = useCallback(() => {
    if (success) {
      let group = groupList.find((x) => x.candidate_group_id === success);
      setSelectedRole(group);
    }
  }, [success, groupList]);
  useEffect(() => {
    apiClear();
  }, [success, apiClear]);

  // FUNCTIONS
  const handleDropDown = (role, i) => {
    let data = {
      check_id: userDetail.check_id,
      candidate_group_id: role.candidate_group_id,
    };
    dispatch(updateCandidateGroup(data));
  };

  return (
    <Card small className="mb-4 pt-3">
      <CardHeader className="text-center">
        <div className="mb-3 mx-auto">
          <img
            className="rounded-circle"
            src={candidateDefaultAvatar}
            alt={userDetail.candidate_details.candidate_name}
            width="110"
          />
        </div>
        <h4 className="mb-0">{userDetail.candidate_details.candidate_name}</h4>
        <span className="text-muted d-block">
          {userDetail.candidate_details.designation}
        </span>
        <span className="text-muted d-block">
          {userDetail.candidate_details.email}
        </span>
        <span className="text-muted d-block">
          {userDetail.candidate_details.phone_number}
        </span>
        <span className="text-muted d-block mb-2">
          Requested by {userDetail.created_by.email}
        </span>

        <div style={{ display: "inline-block" }}>
          <>
            {loading ? (
              <PageSpinner />
            ) : (
              <Dropdown
                className="dropDownStyle"
                open={dropdown}
                toggle={() => setDropdown(!dropdown)}
              >
                <DropdownToggle
                  caret
                  style={{
                    backgroundColor: "transparent",
                    color: "#000",
                    borderColor: "transparent",
                  }}
                >
                  {selectedRole.candidate_group_name}
                </DropdownToggle>
                <DropdownMenu>
                  {groupList
                    ? groupList.map((role, i) => (
                        <DropdownItem
                          key={i}
                          onClick={() => handleDropDown(role, i)}
                        >
                          {role.candidate_group_name}
                        </DropdownItem>
                      ))
                    : null}
                </DropdownMenu>
              </Dropdown>
            )}
          </>
        </div>
      </CardHeader>
      <CardFooter style={{ paddingTop: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="mb-3">
            <img
              src={image}
              alt={userDetail.check_completed_status}
              width="80"
            />
          </div>
          <div style={{ marginLeft: "20px" }}>
            <span
              style={{
                fontWeight: "bold",
                color: "#333",
                fontSize: "15px",
              }}
            >
              {userDetail.check_status_words}
            </span>
            <span className="text-muted d-block mb-2">{userDetail.eta}</span>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default UserInfo;
