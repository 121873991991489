import {
  put,
  takeLatest,
  takeEvery,
  call,
  delay as sagaDelay,
} from "redux-saga/effects";
import {
  UPDATE_CANDIDATE_GROUP_ACTION,
  UPDATE_CANDIDATE_GROUP_ERROR,
  UPDATE_CANDIDATE_GROUP_LOADING,
  UPDATE_CANDIDATE_GROUP_SUCCESS,
  CANDIDATES_BY_GROUP_ACTION,
  CANDIDATES_BY_GROUP_ERROR,
  CANDIDATES_BY_GROUP_LOADING,
  CANDIDATES_BY_GROUP_SUCCESS,
  UPDATE_MONITORING_ACTION,
  UPDATE_MONITORING_ERROR,
  UPDATE_MONITORING_LOADING,
  UPDATE_MONITORING_SUCCESS,
  SUPPORT_ACTION,
  SUPPORT_ERROR,
  SUPPORT_LOADING,
  SUPPORT_SUCCESS,
  EXPORT_CHECKS_ACTION,
  UPDATE_EXPORT_CHECK_STATE,
  EXPORT_DOWNLOAD_ZIP_ACTION,
  UPDATE_EXPORT_CHECK_STATE_ERROR,
  EXPORT_CHECK_API_ACTION,
} from "./types";
import { Constant } from "../../lib/Constants";
import { delay } from "../../lib/helper";
import {
  updateCandidateGroup,
  candidatesByGroupApi,
  updateMonitoringApi,
  supportApi,
  pdfReportZipApi,
} from "./api";
import { EXPORT_CHECK_CONSTANT } from "./constants";

function* updateCandidateGroupSaga(data) {
  let errorFlag = false;
  yield put({ type: UPDATE_CANDIDATE_GROUP_LOADING, payload: true });
  try {
    let res = yield call(updateCandidateGroup, data.payload);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: UPDATE_CANDIDATE_GROUP_SUCCESS,
        payload: data.payload.candidate_group_id,
      });
    } else {
      errorFlag = true;
      yield put({
        type: UPDATE_CANDIDATE_GROUP_ERROR,
        payload: res.error_message,
      });
    }
  } catch (error) {
    errorFlag = true;
    yield put({
      type: UPDATE_CANDIDATE_GROUP_ERROR,
      payload: error.toString(),
    });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: UPDATE_CANDIDATE_GROUP_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* candidatesByGroupSaga(data) {
  let { candidateGroupId, withoutLoading = false, search, statuses, page } = data.payload;
  let errorFlag = false;
  if (!withoutLoading)
    yield put({ type: CANDIDATES_BY_GROUP_LOADING, payload: true });
  try {
    let res = yield call(candidatesByGroupApi, candidateGroupId, page, search, statuses);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({ type: CANDIDATES_BY_GROUP_SUCCESS, payload: res.checks, pages: res.pages });
    } else {
      errorFlag = true;
      yield put({
        type: CANDIDATES_BY_GROUP_ERROR,
        payload: res.error_message,
      });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: CANDIDATES_BY_GROUP_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: CANDIDATES_BY_GROUP_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* updateMonitoringSaga(data) {
  yield put({ type: UPDATE_MONITORING_LOADING, payload: true });
  try {
    let res = yield call(updateMonitoringApi, data.payload);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: UPDATE_MONITORING_SUCCESS,
        payload: "Continuous monitoring changed successfully",
      });
    } else {
      yield put({ type: UPDATE_MONITORING_ERROR, payload: res.error_message });
    }
  } catch (error) {
    yield put({ type: UPDATE_MONITORING_ERROR, payload: error.toString() });
  } finally {
    yield call(delay);
    yield put({ type: UPDATE_MONITORING_ERROR, payload: "" });
  }
}

function* supportSaga(data) {
  yield put({ type: SUPPORT_LOADING, payload: true });
  try {
    let res = yield call(supportApi, data.payload);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: SUPPORT_SUCCESS,
        payload: "Your issue submitted successfully",
      });
    } else {
      yield put({ type: SUPPORT_ERROR, payload: res.error_message });
    }
  } catch (error) {
    yield put({ type: SUPPORT_ERROR, payload: error.toString() });
  } finally {
    yield call(delay);
    yield put({ type: SUPPORT_SUCCESS, payload: "" });
  }
}

function* exportCheckApiSaga(data) {
  let groupId = data.payload;
  let maxCall = 20;
  let calls = 0;

  function* checkCall() {
    let resCheck = yield call(pdfReportZipApi, {
      candidate_group_id: groupId,
      action: Constant.ZIP_FILE_ACTIONS.CHECK,
    });
    if (maxCall === calls) throw Error("Maximum api calls reached");
    else if (resCheck?.status !== Constant.RESPONSE_STATUS.SUCCESS) {
      calls++;
      yield sagaDelay(15000);
      yield* checkCall();
    } else {
      yield put({
        type: UPDATE_EXPORT_CHECK_STATE,
        payload: { groupId, state: EXPORT_CHECK_CONSTANT.DOWNLOAD },
      });
    }
  }
  yield* checkCall();
}

function* exportChecksSaga(data) {
  let groupId = data.payload;
  try {
    let resRequest = yield call(pdfReportZipApi, {
      candidate_group_id: groupId,
      action: Constant.ZIP_FILE_ACTIONS.REQUEST,
    });
    if (resRequest.status === Constant.RESPONSE_STATUS.SUCCESS) {
      // UPDATE STATE
      yield put({
        type: UPDATE_EXPORT_CHECK_STATE,
        payload: { groupId, state: EXPORT_CHECK_CONSTANT.GENERATING },
      });

      // CALL CHECK API
      yield put({ type: EXPORT_CHECK_API_ACTION, payload: groupId });
    } else if (
      resRequest?.error_message?.includes("file request is already pending")
    ) {
      yield put({
        type: UPDATE_EXPORT_CHECK_STATE,
        payload: { groupId, state: EXPORT_CHECK_CONSTANT.DOWNLOAD },
      });
    } else throw Error(resRequest.error_message);
  } catch (error) {
    yield put({
      type: UPDATE_EXPORT_CHECK_STATE_ERROR,
      payload: error.message,
    });
    yield put({
      type: UPDATE_EXPORT_CHECK_STATE,
      payload: { groupId, state: EXPORT_CHECK_CONSTANT.EXPORT },
    });
  } finally {
    yield call(delay);
    yield put({ type: UPDATE_EXPORT_CHECK_STATE_ERROR, payload: "" });
  }
}

function* exportDownloadZip(data) {
  let groupId = data.payload.groupId;
  try {
    let groupName = data.payload.groupName;
    let res = yield call(pdfReportZipApi, {
      candidate_group_id: groupId,
      groupName,
      action: Constant.ZIP_FILE_ACTIONS.CONSUME,
    });
    if (res)
      yield put({
        type: UPDATE_EXPORT_CHECK_STATE,
        payload: { groupId, state: EXPORT_CHECK_CONSTANT.EXPORT },
      });
    else throw Error("Something went wrong");
  } catch (error) {
    yield put({
      type: UPDATE_EXPORT_CHECK_STATE_ERROR,
      payload: error.message,
    });
    yield put({
      type: UPDATE_EXPORT_CHECK_STATE,
      payload: { groupId, state: EXPORT_CHECK_CONSTANT.EXPORT },
    });
  } finally {
    yield call(delay);
    yield put({
      type: UPDATE_EXPORT_CHECK_STATE_ERROR,
      payload: "",
    });
  }
}

function* watchCandidatesSaga() {
  yield takeLatest(UPDATE_CANDIDATE_GROUP_ACTION, updateCandidateGroupSaga);
  yield takeLatest(CANDIDATES_BY_GROUP_ACTION, candidatesByGroupSaga);
  yield takeLatest(UPDATE_MONITORING_ACTION, updateMonitoringSaga);
  yield takeLatest(SUPPORT_ACTION, supportSaga);
  yield takeEvery(EXPORT_CHECKS_ACTION, exportChecksSaga);
  yield takeEvery(EXPORT_DOWNLOAD_ZIP_ACTION, exportDownloadZip);
  yield takeEvery(EXPORT_CHECK_API_ACTION, exportCheckApiSaga);
}
export default watchCandidatesSaga;
