import { GET_CHART_ACTION, GET_PIE_DATA_ACTION } from "./types";

export const getChartsAction = () => ({
  type: GET_CHART_ACTION,
});

export const getPieDataAction = (data) => ({
  type: GET_PIE_DATA_ACTION,
  payload: data,
});
