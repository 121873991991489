import React, { useState } from "react";
import { Card, CardHeader, CardBody, Button } from "shards-react";
import { Pie } from "react-chartjs-2";
import { pieChart_options } from "../../lib/dummyData";
import { useSelector, useDispatch } from "react-redux";
import { getPieDataAction } from "../../views/Analytics/action";
import { cleanText } from "../../lib/helper";
import { Error } from "../index";

const PieChart = () => {
  const dispatch = useDispatch();

  const { pieChartList, pieChartData, pieDataError } = useSelector((state) => ({
    pieChartList: state.analyticsReducer.pieChartList,
    pieChartData: state.analyticsReducer.pieChartData,
    pieDataError: state.analyticsReducer.pieDataError,
  }));

  const [segment, setSegment] = useState("");
  const [btn, setBtn] = useState(pieChartList[0]);
  const [selectedSegment, setSelectedSegment] = useState("");
  const [disabled, setDisabled] = useState([pieChartList[0]]);

  // FUNCTIONS
  const handleButtonClick = (button) => {
    setBtn(button);
    if (segment) {
      setDisabled([button, cleanText(Object.keys(segment)[0])]);
      dispatch(
        getPieDataAction({
          curr_click: cleanText(button, true),
          previous_clicks: segment,
        })
      );
      setSegment("");
    } else {
      setDisabled([button]);
      dispatch(getPieDataAction({ curr_click: cleanText(button, true) }));
      setSelectedSegment("");
    }
  };
  const handleChartClick = (elems) => {
    if (elems.length > 0 && elems[0]._view && elems[0]._view.label) {
      let obj = {};
      obj[pieChartData.label] = elems[0]._view.label;
      setSegment(obj);
      setSelectedSegment(elems[0]._view.label);
    }
  };
  const handleReset = () => {
    setBtn(pieChartList[0]);
    setDisabled([pieChartList[0]]);
    setSegment("");
    setSelectedSegment("");
    dispatch(getPieDataAction({ curr_click: cleanText(pieChartList[0]) }));
  };

  return (
    <>
      <Card small className="h-100">
        {pieDataError && <Error error message={pieDataError} />}
        <CardHeader>
          <span
            className="float-right pointer"
            onClick={handleReset}
            style={{
              fontSize: 12,
              fontWeight: "normal",
              marginRight: 10,
            }}
          >
            Reset
          </span>
        </CardHeader>
        <CardBody className="d-flex py-0 pb-3">
          <div style={{ display: "flex", flex: 1 }}>
            <div
              style={{
                flex: 0.3,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {pieChartList && pieChartList.length > 0
                ? pieChartList.map((title, i) => (
                    <Button
                      outline
                      disabled={disabled.includes(title)}
                      key={i}
                      size="sm"
                      theme="primary"
                      className="text-center"
                      style={{
                        width: "90%",
                        margin: 5,
                        cursor: disabled.includes(title)
                          ? "not-allowed"
                          : "pointer",
                      }}
                      onClick={() => handleButtonClick(title)}
                    >
                      {title}
                    </Button>
                  ))
                : null}
            </div>

            <div style={{ flex: 0.7 }}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <span>{btn}</span>
                {selectedSegment ? <span> {selectedSegment}</span> : null}
              </div>
              <br />

              {pieChartData ? (
                <Pie
                  // redraw
                  data={pieChartData.data}
                  onElementsClick={handleChartClick}
                  options={pieChart_options}
                />
              ) : null}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default PieChart;
