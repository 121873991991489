import React from "react";
import { Card, CardBody, CardFooter, CardHeader } from "shards-react";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import { checkStatus } from "../../lib/dummyData";

const dates = [
  {
    name: "Initiation Date",
    value: "initiation_timestamp",
  },
  {
    name: "Start Date",
    value: "created_timestamp",
  },
  {
    name: "Completed Date",
    value: "completed_timestamp"
  }
]

const UserCard = ({ user, handleClick }) => {
  let { highlightColor, image } = checkStatus.find(
    (c) => c.name === user.check_completed_status
  );

  return (
    <Card
      className="h-100 pointer d-flex align-items-stretch"
      onClick={() => handleClick(user)}
    >
      {/* NAME AND ROLE */}
      <CardHeader className="border-bottom text-left">
        <h4 className="mb-0">{user.candidate_details.candidate_name}</h4>
        <span className="text-muted d-block mb-2">
          {user.candidate_details.designation}
        </span>
        <div>
          {dates.map((date, i) => {
            return (
              <span className="mr-1">
                <OverlayTrigger
                  key={`${date.value}-${i}`}
                  placement="top"
                  overlay={
                    <Tooltip id={`${date.value}-${i}`}>
                      <strong>{date.name}</strong>: <br/>{user[date.value] ? user[date.value]: 'Pending'}
                    </Tooltip>
                  }
                >
                  <Badge
                    pill
                    id={`${date.value}-${i}`}
                    variant={user[date.value] ? 'success' : 'secondary'}
                  >
                    {date.name[0]}
                  </Badge>
                </OverlayTrigger>
              </span>
            );
          })}
        </div>
      </CardHeader>

      {/* STATUS */}
      <CardBody className="border-bottom text-left">
        <div
          className="text-left"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              alt={user.check_completed_status}
              width="100px"
              height="100px"
              className="mr-2 my-2"
            />
          </div>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "15px",
            }}
          >
            {user.check_status_words}
          </span>
        </div>
      </CardBody>

      {/* FOOTER */}
      <CardFooter className="text-left">
        <span className="text-muted d-block mb-2">{user.eta}</span>
      </CardFooter>
    </Card>
  );
};

export default UserCard;
