import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  FormInput,
  Button,
  FormFeedback,
} from "shards-react";
import { invalidEmail } from "../../lib/helper";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Error } from "../index";
import { inviteNewUserAction } from "../../views/Settings/action";

const InviteMemberModal = ({ open, toggle, setModal }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const {
    inviteUserError,
    inviteUserLoading,
    inviteUserSuccess,
    setInviteModal,
  } = useSelector((state) => ({
    inviteUserLoading: state.settingsReducer.inviteUserLoading,
    inviteUserSuccess: state.settingsReducer.inviteUserSuccess,
    inviteUserError: state.settingsReducer.inviteUserError,
  }));

  // FOR INVITE
  const cb = useCallback(() => {
    toggle();
    setEmail("");
  }, [toggle, setEmail]);
  useEffect(() => {
    if (inviteUserSuccess) {
      setTimeout(() => {
        cb();
      }, 3000);
    }
  }, [inviteUserSuccess, cb]);

  // FUNCTIONS
  const handleInviteUser = (e) => {
    e.preventDefault();
    if (invalidEmail(email)) {
      setEmailError(invalidEmail(email));
    } else {
      setEmailError("");
      dispatch(inviteNewUserAction({ new_user_email: email }));
    }
  };

  return (
    <Modal open={open} toggle={() => toggle()} centered>
      <ModalHeader>Invite organization member</ModalHeader>
      <div>
        {inviteUserError && <Error error message={inviteUserError} />}
        {inviteUserSuccess && <Error success message={inviteUserSuccess} />}
      </div>
      <ModalBody style={{ paddingTop: 20, paddingBottom: 10 }}>
        <>
          {/* EMAIL FORM */}
          <Form onSubmit={handleInviteUser}>
            <FormGroup>
              <label htmlFor="email">Email address</label>
              <FormInput
                id="email"
                name="email"
                type="text"
                placeholder="Enter email"
                invalid={emailError.length > 0}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError ? <FormFeedback>{emailError}</FormFeedback> : null}
            </FormGroup>
            <FormGroup style={{ marginTop: 20 }}>
              <Button theme="primary" type="submit">
                {inviteUserLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Invite"
                )}
              </Button>
              <Button
                theme="danger"
                style={{ marginLeft: 15 }}
                onClick={() => {
                  setModal(false);
                  setEmailError("");
                  setEmail("");
                }}
              >
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </>
      </ModalBody>
    </Modal>
  );
};

export default InviteMemberModal;
