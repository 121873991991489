import React from "react";
import { Modal, ModalHeader, ModalBody } from "shards-react";
import { PageSpinner, Error } from "../index";
import { useSelector } from "react-redux";
import "../../styles/modalScroll.css";

const ActivityModal = ({ open, toggle }) => {
  const { activityError, activityLoading, activitySuccess } = useSelector(
    (state) => ({
      activityLoading: state.settingsReducer.activityLoading,
      activitySuccess: state.settingsReducer.activitySuccess,
      activityError: state.settingsReducer.activityError,
    })
  );

  return (
    <Modal open={open} toggle={() => toggle()} centered>
      <ModalHeader>Activity</ModalHeader>
      <div>{activityError && <Error error message={activityError} />}</div>
      <ModalBody
        className="myModal"
        style={{ maxHeight: window.innerHeight - 200, overflowY: "auto" }}
      >
        {activityLoading ? (
          <PageSpinner />
        ) : (
          <div>
            <span style={{ whiteSpace: "pre-line" }}>
              {activitySuccess || activityError
                ? activitySuccess
                : "Acitvity is empty"}
            </span>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ActivityModal;
