import React from "react";
import { Modal, ModalBody } from "shards-react";
import "../../styles/modalScroll.css";

const CommentsModal = ({ open, toggle, data }) => {
  const updatedData = data.replace(/<table>/ig, '<table class="table table-bordered">');
  return (
    <Modal open={open} toggle={toggle} centered>
      <ModalBody
        className="myModal"
        style={{ maxHeight: window.innerHeight - 200, overflowY: "auto" }}
      >
        <div className="text-center">
          <span
            style={{ whiteSpace: "pre-line", fontSize: 14 }}
            dangerouslySetInnerHTML={{ __html: updatedData }}
          ></span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CommentsModal;
