import React from "react";
import { Redirect } from "react-router-dom";

// VIEWS
import {
  Settings,
  Analytics,
  CreateCheck,
  Candidates,
  CandidateDetail,
  ChangePassword,
  ChangeWebhook,
  NewGroup, DownloadMis,
} from "./views";

export default [
  {
    path: "/",
    component: () => <Redirect to="/candidates/" />,
  },
  {
    path: "/candidates",
    component: Candidates,
  },
  {
    path: "/candidates/:groupId",
    component: Candidates,
  },
  {
    path: "/newGroup",
    component: NewGroup,
  },
  {
    path: "/candidates/:groupId/:id",
    component: CandidateDetail,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/analytics",
    component: Analytics,
  },
  {
    path: "/create_check",
    component: CreateCheck,
  },
  {
    path: "/change-password",
    component: ChangePassword,
  },
  {
    path: "/change-webhook",
    component: ChangeWebhook,
  },
  {
    path: "/download-mis",
    component: DownloadMis,
  }
];
