import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col,
} from "shards-react";
import { Checks, CommentsModal } from "../index";
import { checks as predefinedChecks } from "../../lib/dummyData";

const CheckRequestedCard = ({
  userDetail,
  createCheck,
  collarButtons,
  selectedCollarHandle,
  selectedCollarValue,
  checksList,
  unRequestedChecks,
  setUnRequestedChecks,
}) => {
  const [commentModal, setCommentModal] = useState(false);
  const [commentData, setCommentData] = useState("");

  // FUNCTIONS
  const handleIsActive = (check, list) => {
    let t = list.filter((l) => l.check_type === check.name);
    return !!(t && t.length > 0);
  };

  const handleTint = (check, list) => {
    let tint = list.filter((l) => l.check_type === check.name);
    if (tint && tint.length > 0) {
      return tint[0];
    } else {
      return false;
    }
  };

  const handleCheckClick = (check) => {
    setCommentData(check.comments);
    setCommentModal(!commentModal);
  };

  return (
    <>
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Checks Requested</h6>
        </CardHeader>
        {/* CANDIDATE DETAIL */}
        <CardBody>
          <Row className="pl-3">
            {/* COLLAR BUTTONS */}
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {collarButtons
                  ? collarButtons.map((button, i) => (
                      <Button
                        key={i}
                        theme={
                          selectedCollarValue === i ? "primary" : "secondary"
                        }
                        onClick={() => selectedCollarHandle(i)}
                        className="mx-2 mb-4"
                      >
                        {button.title}
                      </Button>
                    ))
                  : null}
              </div>
            </Col>
          </Row>
        </CardBody>

        <CardFooter>
          {/* CHECKS */}
          <div className="grid">
            {!createCheck ? (
              // CANDIDATE DETAIL
              checksList && predefinedChecks ? (
                predefinedChecks.map((check, i) => (
                  <Checks
                    key={i}
                    check={check}
                    isActive={handleIsActive(check, checksList)}
                    checkDetail={handleTint(check, checksList)}
                    handleClick={(check) => handleCheckClick(check)}
                  />
                ))
              ) : (
                <span>No checks to show</span>
              )
            ) : // CREATE CHECK
            checksList && predefinedChecks ? (
              predefinedChecks.map((check, i) => (
                <Checks
                  isCreateCheck={createCheck}
                  key={i}
                  check={check}
                  isActive={checksList.includes(check.name)}
                  unRequestedChecks={unRequestedChecks}
                  setUnRequestedChecks={setUnRequestedChecks}
                />
              ))
            ) : (
              <span style={{ marginBottom: 10 }}>No checks to show</span>
            )}
          </div>
        </CardFooter>
      </Card>
      <CommentsModal
        open={commentModal}
        toggle={() => setCommentModal(!commentModal)}
        data={commentData}
      />
    </>
  );
};

export default CheckRequestedCard;
