import { put, takeLatest, call } from "redux-saga/effects";
import { Constant } from "../../lib/Constants";
import { delay } from "../../lib/helper";
import {
  CHANGE_PASSWORD_ACTION,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PERMISSION_ACTION,
  CHANGE_PERMISSION_ERROR,
  CHANGE_PERMISSION_LOADING,
  CHANGE_PERMISSION_SUCCESS,
  GET_ORG_USERS_ACTION,
  GET_ORG_USERS_LOADING,
  GET_ORG_USERS_SUCCESS,
  GET_ORG_USERS_ERROR,
  GET_ACTIVITY_ACTION,
  GET_ACTIVITY_ERROR,
  GET_ACTIVITY_LOADING,
  GET_ACTIVITY_SUCCESS,
  INVITE_USER_ACTION,
  INVITE_USER_ERROR,
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  EMAIL_NOTIFICATION_ACTION,
  EMAIL_NOTIFICATION_ERROR,
  EMAIL_NOTIFICATION_LOADING,
} from "./types";
import { UPDATE_USER_INFO } from "../../layouts/types";
import { GET_USER_INFO_ACTION } from "../../layouts/types";
import {
  changePasswordApi,
  getOrganizationUsersApi,
  changePermissionApi,
  getActivityApi,
  inviteUserApi,
  changeEmailNotificationApi,
} from "./api";

function* changePasswordSaga(data) {
  yield put({ type: CHANGE_PASSWORD_LOADING, payload: true });
  try {
    let { history } = data.payload;
    delete data.payload.history;

    let res = yield call(changePasswordApi, data.payload);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: "Details successfully changed. Redirecting to login....",
      });
      yield localStorage.clear();
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else {
      yield put({
        type: CHANGE_PASSWORD_ERROR,
        payload: res.error_message,
      });
    }
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_ERROR, payload: error.toString() });
  } finally {
    yield call(delay);
    yield put({ type: CHANGE_PASSWORD_ERROR, payload: "" });
  }
}

function* getOrgUsersSaga() {
  let errorFlag = false;
  yield put({ type: UPDATE_USER_INFO });
  yield put({ type: GET_ORG_USERS_LOADING, payload: true });
  try {
    let res = yield call(getOrganizationUsersApi);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({ type: GET_ORG_USERS_SUCCESS, payload: res.org_users });
    } else {
      errorFlag = true;
      yield put({
        type: GET_ORG_USERS_ERROR,
        payload: "Failed while getting organization users",
      });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: GET_ORG_USERS_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: GET_ORG_USERS_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* changePermissionSaga(data) {
  let errorFlag = false;
  yield put({ type: CHANGE_PERMISSION_LOADING, payload: true });
  try {
    let res = yield call(changePermissionApi, data.payload);
    if (
      res.status.toUpperCase() ===
      Constant.RESPONSE_STATUS.SUCCESS.toUpperCase()
    ) {
      yield put({
        type: CHANGE_PERMISSION_SUCCESS,
        payload: String("permission changed"),
      });
      yield getOrgUsersSaga();
    } else {
      errorFlag = true;
      yield put({
        type: CHANGE_PERMISSION_ERROR,
        payload: res.error_message,
      });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: CHANGE_PERMISSION_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: CHANGE_PERMISSION_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* getActivitySaga(data) {
  let errorFlag = false;
  yield put({ type: GET_ACTIVITY_LOADING, payload: true });

  try {
    let res = yield call(getActivityApi, data.userId);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({ type: GET_ACTIVITY_SUCCESS, payload: res.activity_log });
    } else {
      errorFlag = true;
      yield put({
        type: GET_ACTIVITY_ERROR,
        payload: res.error_message,
      });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: GET_ACTIVITY_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: GET_ACTIVITY_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* inviteUserSaga(data) {
  yield put({ type: INVITE_USER_LOADING, payload: true });
  try {
    let res = yield call(inviteUserApi, data);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({ type: INVITE_USER_SUCCESS, payload: "Invited member" });
    } else {
      yield put({ type: INVITE_USER_ERROR, payload: res.error_message });
    }
  } catch (error) {
    yield put({ type: INVITE_USER_ERROR, payload: error.toString() });
  } finally {
    yield call(delay);
    yield put({ type: INVITE_USER_ERROR, payload: "" });
  }
}

function* emailNotificationSaga(data) {
  let errorFlag = false;
  yield put({ type: EMAIL_NOTIFICATION_LOADING, payload: true });

  try {
    let res = yield call(changeEmailNotificationApi, data.payload);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({ type: GET_USER_INFO_ACTION });
    } else {
      errorFlag = true;
      yield put({ type: EMAIL_NOTIFICATION_ERROR, payload: res.error_message });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: EMAIL_NOTIFICATION_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: EMAIL_NOTIFICATION_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* watchSettingsSaga() {
  yield takeLatest(CHANGE_PASSWORD_ACTION, changePasswordSaga);
  yield takeLatest(GET_ORG_USERS_ACTION, getOrgUsersSaga);
  yield takeLatest(CHANGE_PERMISSION_ACTION, changePermissionSaga);
  yield takeLatest(GET_ACTIVITY_ACTION, getActivitySaga);
  yield takeLatest(INVITE_USER_ACTION, inviteUserSaga);
  yield takeLatest(EMAIL_NOTIFICATION_ACTION, emailNotificationSaga);
}
export default watchSettingsSaga;
