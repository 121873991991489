import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";

import { Store } from "../../../flux";

import { useSelector } from "react-redux";

const MainSidebar = (props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [sidebarNavItems, setSidebarNavItems] = useState(
    Store.getSidebarItems()
  );

  const { groupList } = useSelector((state) => ({
    groupList: state.userInfoReducer.groupList,
  }));

  useEffect(() => {
    Store.addChangeListener(onChange);
    return () => {
      Store.removeChangeListener(onChange);
    };
  }, []);

  useEffect(() => {
    if (groupList.length > 0) {
      let newSubitems = convertToSidebarForm(groupList);
      newSubitems.push({
        title: "Create new group",
        to: "/newGroup",
        htmlBefore: '<i class="material-icons">add</i>',
      });
      let newsidebar = sidebarNavItems.map((item) => {
        if (item.title === "Candidates") {
          item.subItems = newSubitems;
        }
        return item;
      });
      setSidebarNavItems(newsidebar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupList]);

  // FUNCTIONS
  let onChange = () => {
    setMenuVisible(Store.getMenuState());
    setSidebarNavItems(Store.getSidebarItems());
  };

  let convertToSidebarForm = (data) => {
    let newData = [];
    newData = data.map((item) => {
      let field = {
        id: item.candidate_group_id,
        title: item.candidate_group_name,
        to: `/candidates/${item.candidate_group_id}`,
      };
      return field;
    });
    return newData;
  };

  const classes = classNames(
    "main-sidebar",
    "px-0",
    "col-12",
    menuVisible && "open"
  );

  return (
    <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
      <SidebarMainNavbar hideLogoText={props.hideLogoText} />
      <SidebarNavItems sidebarNavItems={sidebarNavItems} />
    </Col>
  );
};

MainSidebar.propTypes = {
  hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
  hideLogoText: false,
};

export default MainSidebar;
