import React, { useState } from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "../../../../layouts/action";

const Notifications = () => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  const { notifications } = useSelector((state) => ({
    notifications: state.userInfoReducer.notifications,
  }));

  const toggleNotifications = () => {
    if (visible && notifications?.length > 0) dispatch(clearNotification());
    setVisible(!visible);
  };

  const handleClear = () => {
    dispatch(clearNotification());
    setVisible(false);
  };

  return (
    <NavItem className="border-right border-left pointer dropdown notifications">
      <NavLink
        className="nav-link-icon text-center"
        onClick={toggleNotifications}
      >
        <div className="nav-link-icon__wrapper" style={{ userSelect: "none" }}>
          <i className="material-icons">&#xE7F4;</i>
          <Badge pill theme="danger">
            {notifications?.length || 0}
          </Badge>
        </div>
      </NavLink>
      <Collapse open={visible} className="dropdown-menu dropdown-menu-small">
        {notifications?.length > 0 ? (
          notifications.map((notification, i) => (
            <DropdownItem key={i}>
              <div className="notification__content">
                <span className="notification__category">
                  {notification?.timestamp || ""}
                </span>
                <p>{notification?.content || ""}</p>
              </div>
            </DropdownItem>
          ))
        ) : (
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>No new notifications</span>
          </div>
        )}
        {notifications.length !== 0 && (
          <DropdownItem
            className="notification__all text-center"
            onClick={handleClear}
          >
            Clear Notifications
          </DropdownItem>
        )}
      </Collapse>
    </NavItem>
  );
};
export default Notifications;
