import React, { useRef, useEffect } from "react";
import { Card, CardHeader, CardBody } from "shards-react";
import Chart from "../../utils/chart";
import { lineChartOptions } from "../../lib/dummyData";

const LineChart = ({ data }) => {
  let canvasRef = useRef();

  useEffect(() => {
    const lineChart = new Chart(canvasRef.current, {
      type: "LineWithLine",
      data: data,
      options: lineChartOptions,
    });

    const buoMeta = lineChart.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[data.datasets[0].data.length - 1]._model.radius = 0;

    lineChart.render();
    // eslint-disable-next-line
  }, []);

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{data.title}</h6>
      </CardHeader>
      <CardBody className="pt-0">
        <canvas
          height="120"
          ref={canvasRef}
          style={{ maxWidth: "100% !important" }}
        />
      </CardBody>
    </Card>
  );
};
export default LineChart;
