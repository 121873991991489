import React from "react";

const PermissionCheck = ({ message, isChecked, handlePermissionClick }) => {
  const handleClick = () => {
    handlePermissionClick();
  };

  return (
    <>
      <span
        className={isChecked ? "text-success" : "text-danger"}
        style={{ userSelect: "none", cursor: "pointer" }}
        onClick={() => handleClick()}
      >
        {isChecked ? (
          <span className="text-success">&#10003; </span>
        ) : (
          <span className="text-danger">&#10005; </span>
        )}
        {message}
      </span>
    </>
  );
};

export default PermissionCheck;
