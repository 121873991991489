export default function () {
  return [
    {
      title: "Candidates",
      htmlBefore: '<i class="material-icons">supervisor_account</i>',
      subItems: [],
    },
    {
      title: 'Download MIS',
      htmlBefore: '<i class="material-icons">download</i>',
      to: '/download-mis',
    },
    {
      title: "Settings",
      to: "/settings",
      htmlBefore: '<i class="material-icons">settings</i>',
    },
    {
      title: "Analytics",
      to: "/analytics",
      htmlBefore: '<i class="material-icons">addchart</i>',
    },
    {
      title: "Create new check",
      to: "/create_check",
      htmlBefore: '<i class="material-icons">add</i>',
    },
  ];
}
