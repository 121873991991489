import createReducer from "../../lib/createReducer";
import {
  LOGIN_LOADING,
  LOGIN_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
} from "./types";

let initialState = {
  error: "",
  loading: false,
  userDetail: {},
  forgotPasswordLoading: false,
  forgotPasswordSuccess: "",
  forgotPasswordError: "",
};

export const loginReducer = createReducer(initialState, {
  // LOGIN
  [LOGIN_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
      error: "",
    });
  },
  [LOGIN_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      userDetail: {},
      error: action.payload,
    });
  },

  // FORGOT PASSWORD
  [FORGOT_PASSWORD_LOADING](state, action) {
    return Object.assign({}, state, {
      forgotPasswordLoading: action.payload,
      forgotPasswordError: "",
      forgotPasswordSuccess: "",
    });
  },
  [FORGOT_PASSWORD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      forgotPasswordLoading: false,
      forgotPasswordSuccess: action.payload,
      forgotPasswordError: "",
    });
  },
  [FORGOT_PASSWORD_ERROR](state, action) {
    return Object.assign({}, state, {
      forgotPasswordLoading: false,
      forgotPasswordSuccess: "",
      forgotPasswordError: action.payload,
    });
  },
});
