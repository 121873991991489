import React from "react";
import { Card, CardBody, CardHeader } from "shards-react";

const AIComments = ({ aiComments }) => {
  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">TraqCheck AI Comments</CardHeader>
      <CardBody className="border-bottom" style={{ paddingBottom: 30 }}>
        <div className="text-center mt-4">
          <span style={{ whiteSpace: "pre-line", fontSize: 14 }}
            dangerouslySetInnerHTML={{ __html: aiComments }}>
          </span>
        </div>
      </CardBody>
    </Card>
  );
};

export default AIComments;
