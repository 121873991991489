import React from "react";
import { Card, CardBody, Button, FormCheckbox } from "shards-react";
import { useHistory } from "react-router-dom";

function ChangePasswordSection({ handleNotification, notificationValue }) {
  let history = useHistory();

  const navigateChangePassword = () => {
    history.push("/change-password");
  };

  const navigateChangeWebhook = () => {
    history.push("/change-webhook");
  };

  return (
    <Card small className="mb-4">
      <CardBody>
        {/* send notifications check box */}
        <div>
          <FormCheckbox
            inline
            onChange={handleNotification}
            checked={notificationValue}
          >
            Send notifications to email
          </FormCheckbox>
        </div>
        {/* change password */}
        <div className="pt-3">
          <Button theme="primary" onClick={navigateChangePassword}>
            Change Password
          </Button>
        </div>
        {/* change webhook */}
        <div className="pt-3">
          <Button theme="primary" onClick={navigateChangeWebhook}>
            Change Webhook URL
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}

export default ChangePasswordSection;
