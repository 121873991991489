import createReducer from "../../lib/createReducer";
import {
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PERMISSION_LOADING,
  CHANGE_PERMISSION_SUCCESS,
  CHANGE_PERMISSION_ERROR,
  GET_ORG_USERS_LOADING,
  GET_ORG_USERS_ERROR,
  GET_ORG_USERS_SUCCESS,
  GET_ACTIVITY_LOADING,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_ERROR,
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  EMAIL_NOTIFICATION_LOADING,
  EMAIL_NOTIFICATION_ERROR,
} from "./types";

let initialState = {
  loading: false,
  error: "",
  success: "",
  orgUsers: [],

  permissionError: "",
  permissionSuccess: "",
  permissionLoading: false,

  activityLoading: false,
  activitySuccess: "",
  activityError: "",

  inviteUserLoading: false,
  inviteUserSuccess: "",
  inviteUserError: "",
};

export const settingsReducer = createReducer(initialState, {
  // CHANGE PASSWORD
  [CHANGE_PASSWORD_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
    });
  },
  [CHANGE_PASSWORD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: "",
    });
  },
  [CHANGE_PASSWORD_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: "",
      error: action.payload,
    });
  },

  // ORG USERS
  [GET_ORG_USERS_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
      error: "",
    });
  },
  [GET_ORG_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      orgUsers: action.payload,
      error: "",
    });
  },
  [GET_ORG_USERS_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      orgUsers: [],
      error: action.payload,
    });
  },

  //CHANGE PERMISSION
  [CHANGE_PERMISSION_LOADING](state, action) {
    return Object.assign({}, state, {
      permissionLoading: action.payload,
      permissionError: "",
    });
  },
  [CHANGE_PERMISSION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      permissionLoading: false,
      permissionSuccess: action.payload,
      permissionError: "",
    });
  },
  [CHANGE_PERMISSION_ERROR](state, action) {
    return Object.assign({}, state, {
      permissionLoading: false,
      permissionError: action.payload,
    });
  },

  // GET ACTIVITY
  [GET_ACTIVITY_LOADING](state, action) {
    return Object.assign({}, state, {
      activityLoading: action.payload,
      activityError: "",
    });
  },
  [GET_ACTIVITY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      activityLoading: false,
      activitySuccess: action.payload,
      activityError: "",
    });
  },
  [GET_ACTIVITY_ERROR](state, action) {
    return Object.assign({}, state, {
      activityLoading: false,
      activitySuccess: "",
      activityError: action.payload,
    });
  },

  // INVITE USER
  [INVITE_USER_LOADING](state, action) {
    return Object.assign({}, state, {
      inviteUserLoading: action.payload,
      inviteUserError: "",
    });
  },
  [INVITE_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      inviteUserLoading: false,
      inviteUserSuccess: action.payload,
      inviteUserError: "",
    });
  },
  [INVITE_USER_ERROR](state, action) {
    return Object.assign({}, state, {
      inviteUserLoading: false,
      inviteUserSuccess: "",
      inviteUserError: action.payload,
    });
  },

  // EMAIL NOTIFICATION
  [EMAIL_NOTIFICATION_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
      error: "",
    });
  },
  [EMAIL_NOTIFICATION_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      error: action.payload,
    });
  },
});
