import React from "react";
import { Container, Row } from "shards-react";

const MainFooter = () => (
  <footer className="main-footer d-flex bg-white border-top">
    <Container fluid={false} className="my-auto">
      <Row>
        <span className="copyright my-auto ml-auto mr-2">
          Copyright © {(new Date()).getFullYear()} TraqCheck
        </span>
      </Row>
    </Container>
  </footer>
);

export default MainFooter;
