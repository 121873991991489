import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormInput,
  FormGroup,
  Button,
  FormFeedback,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAction } from "../Settings/action";
import { Spinner } from "react-bootstrap";
import { Error } from "../../components";
import { invalidPassword, invalidConfPassword } from "../../lib/helper";

function ChangePassword() {
  let history = useHistory();
  let dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { loading, success, error } = useSelector((state) => ({
    loading: state.settingsReducer.loading,
    success: state.settingsReducer.success,
    error: state.settingsReducer.error,
  }));

  // FUNCTIONS
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      invalidPassword(oldPassword).length > 0 ||
      invalidPassword(newPassword).length > 0 ||
      invalidConfPassword(newPassword, confirmPassword).length > 0
    ) {
      setErrors({
        oldPassword: invalidPassword(oldPassword),
        newPassword: invalidPassword(newPassword),
        confirmPassword: invalidConfPassword(newPassword, confirmPassword),
      });
    } else {
      setErrors({ oldPassword: "", newPassword: "", confirmPassword: "" });
      dispatch(
        changePasswordAction({
          old_password: oldPassword,
          new_password: newPassword,
          history,
        })
      );
    }
  };

  return (
    <>
      {error && <Error error message={error} />}
      {success && <Error success message={success} />}
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/* back button */}
          <i
            className="fas fa-arrow-left mr-3"
            style={{ fontSize: "1.8em", color: "#3d5170", cursor: "pointer" }}
            onClick={() => history.goBack()}
          ></i>

          <PageTitle sm="4" title="Change Password" className="text-sm-left" />
        </Row>

        <Row className="px-2">
          <Col lg="6">
            <Card small className="mb-4">
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  {/* Old Password */}
                  <FormGroup>
                    <label htmlFor="oldPassword">Old Password</label>
                    <FormInput
                      type="password"
                      id="oldPassword"
                      placeholder="Old Password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      invalid={errors.oldPassword.length > 0}
                    />
                    {errors.oldPassword ? (
                      <FormFeedback>{errors.oldPassword}</FormFeedback>
                    ) : null}
                  </FormGroup>

                  {/* New Password */}
                  <FormGroup>
                    <label htmlFor="newPassword">New Password</label>
                    <FormInput
                      type="password"
                      id="newPassword"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      invalid={errors.newPassword.length > 0}
                    />
                    {errors.newPassword && (
                      <FormFeedback>{errors.newPassword}</FormFeedback>
                    )}
                  </FormGroup>

                  {/* Confirm New Password */}
                  <FormGroup>
                    <label htmlFor="confirmNewPassword">
                      Confirm New Password
                    </label>
                    <FormInput
                      type="password"
                      id="confirmNewPassword"
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      invalid={errors.confirmPassword.length > 0}
                    />
                    {errors.confirmPassword && (
                      <FormFeedback>{errors.confirmPassword}</FormFeedback>
                    )}
                  </FormGroup>

                  <FormGroup className="pt-3">
                    <Button type="submit">
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Change Password"
                      )}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChangePassword;
