export const CANDIDATES_BY_GROUP_ACTION = "CANDIDATES_BY_GROUP_ACTION";
export const CANDIDATES_BY_GROUP_LOADING = "CANDIDATES_BY_GROUP_LOADING";
export const CANDIDATES_BY_GROUP_SUCCESS = "CANDIDATES_BY_GROUP_SUCCESS";
export const CANDIDATES_BY_GROUP_ERROR = "CANDIDATES_BY_GROUP_ERROR";

export const UPDATE_CANDIDATE_GROUP_ACTION = "UPDATE_CANDIDATE_GROUP_ACTION";
export const UPDATE_CANDIDATE_GROUP_LOADING = "UPDATE_CANDIDATE_GROUP_LOADING";
export const UPDATE_CANDIDATE_GROUP_SUCCESS = "UPDATE_CANDIDATE_GROUP_SUCCESS";
export const UPDATE_CANDIDATE_GROUP_ERROR = "UPDATE_CANDIDATE_GROUP_ERROR";

export const UPDATE_MONITORING_ACTION = "UPDATE_MONITORING_ACTION";
export const UPDATE_MONITORING_SUCCESS = "UPDATE_MONITORING_SUCCESS";
export const UPDATE_MONITORING_LOADING = "UPDATE_MONITORING_LOADING";
export const UPDATE_MONITORING_ERROR = "UPDATE_MONITORING_ERROR";

export const SUPPORT_ACTION = "SUPPORT_ACTION";
export const SUPPORT_SUCCESS = "SUPPORT_SUCCESS";
export const SUPPORT_ERROR = "SUPPORT_ERROR";
export const SUPPORT_LOADING = "SUPPORT_LOADING";

export const EXPORT_CHECKS_ACTION = "EXPORT_CHECKS_ACTION";
export const EXPORT_DOWNLOAD_ZIP_ACTION = "EXPORT_DOWNLOAD_ZIP_ACTION";
export const EXPORT_CHECK_API_ACTION = "EXPORT_CHECK_API_ACTION";
export const UPDATE_EXPORT_CHECK_STATE = "UPDATE_EXPORT_CHECK_STATE";
export const UPDATE_EXPORT_CHECK_STATE_ERROR =
  "UPDATE_EXPORT_CHECK_STATE_ERROR";
