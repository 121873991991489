import { fork } from "redux-saga/effects";

import watchCandidatesSaga from "../views/Candidates/saga";
import watchGetSpecificCheckSaga from "../views/CandidateDetail/saga";
import watchAnalyticsSaga from "../views/Analytics/saga";
import watchLoginSaga from "../views/Login/saga";
import watchCreateCheckSaga from "../views/CreateCheck/saga";
import watchSettingsSaga from "../views/Settings/saga";
import watchUserInfoSaga from "../layouts/saga";

export function* rootSaga() {
  yield fork(watchCandidatesSaga);
  yield fork(watchGetSpecificCheckSaga);
  yield fork(watchAnalyticsSaga);
  yield fork(watchLoginSaga);
  yield fork(watchCreateCheckSaga);
  yield fork(watchSettingsSaga);
  yield fork(watchUserInfoSaga);
}
