import React from "react";
import { Nav } from "shards-react";

import UserActions from "./UserActions";
import Notifications from "./Notifications";

export default () => (
  <Nav
    navbar
    className="border-left flex-row"
    style={{ justifyContent: "flex-end", flex: 1 }}
  >
    <Notifications />
    <UserActions />
  </Nav>
);
