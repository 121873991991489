import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/shards-dashboards.1.1.0.min.css";
import "./styles/app.css";

import MainRouter from "./router";

export default () => {
  return <MainRouter />;
};
