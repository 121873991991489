import coreApi from "../../lib/coreApi";

export const chartTypesApi = () => {
  let url = "chart_types";
  let result = coreApi.GET(url);
  return result;
};

export const chartDataApi = (data) => {
  let url = "chart_data";
  let result = coreApi.POST(url, data);
  return result;
};
