import { CREATE_CHECK_ACTION, ESTIMATES_ACTION } from "./types";

export const createCheckAction = (data) => ({
  type: CREATE_CHECK_ACTION,
  payload: data,
});

export const getEstimatesAction = (data) => ({
  type: ESTIMATES_ACTION,
  payload: data,
});
