import React from "react";
import { Card, CardHeader } from "shards-react";

function SettingsHeader({ email, isAdmin, organization }) {
  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom" style={{ borderRadius: 10 }}>
        <h6 className="m-0 font-italic">Logged in as {email}</h6>
        <h6 className="m-0 font-italic">
          {isAdmin ? "Admin " : "Member "}
          of {organization}
        </h6>
      </CardHeader>
    </Card>
  );
}

export default SettingsHeader;
