import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_USER_INFO_ACTION,
  GET_USER_INFO_ERROR,
  GET_USER_INFO_LOADING,
  GET_USER_INFO_SUCCESS,
  GET_NOTIFICATION_ACTION,
  CLEAR_NOTIFICATION_ACTION,
  CLEAR_NOTIFICATION_ERROR,
  CLEAR_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  GET_NOTIFICATION_SUCCESS,
  GROUP_LIST_ACTION,
  GROUP_LIST_LOADING,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_ERROR,
  CREATE_GROUP_ACTION,
  CREATE_GROUP_ERROR,
  CREATE_GROUP_LOADING,
  CREATE_GROUP_SUCCESS,
  UPDATE_USER_INFO,
} from "./types";

import {
  getUserInfoApi,
  clearNotificationApi,
  getNotificationApi,
  getGroupList,
  createGroupApi,
} from "./api";
import { Constant } from "../lib/Constants";
import { delay } from "../lib/helper";

function* getUserInfoSaga() {
  let errorFlag = false;
  yield put({ type: GET_USER_INFO_LOADING, payload: true });
  try {
    let res = yield call(getUserInfoApi);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      window.$crisp.push(["set", "user:email", [res.email]]);
      yield put({ type: GET_USER_INFO_SUCCESS, payload: res });
    } else {
      errorFlag = true;
      yield put({
        type: GET_USER_INFO_ERROR,
        payload: res.detail,
      });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: GET_USER_INFO_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: GET_USER_INFO_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* getNotificationSaga() {
  let errorFlag = false;
  try {
    let res = yield call(getNotificationApi);
    yield put({ type: GET_NOTIFICATION_SUCCESS, payload: res });
  } catch (error) {
    errorFlag = true;
    yield put({ type: GET_NOTIFICATION_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: GET_NOTIFICATION_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* clearNotificationSaga() {
  let errorFlag = false;
  try {
    let res = yield call(clearNotificationApi);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({ type: CLEAR_NOTIFICATION_SUCCESS });
    } else {
      errorFlag = true;
      yield put({
        type: CLEAR_NOTIFICATION_ERROR,
        payload: res.error_message,
      });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: CLEAR_NOTIFICATION_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: CLEAR_NOTIFICATION_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* getGroupListSaga() {
  let errorFlag = false;
  yield put({ type: GROUP_LIST_LOADING, payload: true });
  try {
    let res = yield call(getGroupList);
    yield put({ type: GROUP_LIST_SUCCESS, payload: res });
  } catch (error) {
    errorFlag = true;
    yield put({ type: GROUP_LIST_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: GROUP_LIST_ERROR, payload: "" });
      errorFlag = false;
    }
  }
}

function* createGroupSaga(data) {
  yield put({ type: CREATE_GROUP_LOADING, payload: true });
  try {
    let res = yield call(createGroupApi, data.payload);
    if (res.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({ type: GROUP_LIST_ACTION });
      yield put({
        type: CREATE_GROUP_SUCCESS,
        payload: "Group created successfully",
      });
    } else {
      yield put({ type: CREATE_GROUP_ERROR, payload: res.error_message });
    }
  } catch (error) {
    yield put({ type: CREATE_GROUP_ERROR, payload: error.toString() });
  } finally {
    yield call(delay);
    yield put({ type: CREATE_GROUP_SUCCESS, payload: "" });
  }
}

function* updateUserInfoSaga() {
  try {
    let res = yield call(getUserInfoApi);
    if (
      res.status.toUpperCase() ===
      Constant.RESPONSE_STATUS.SUCCESS.toUpperCase()
    ) {
      yield put({ type: GET_USER_INFO_SUCCESS, payload: res });
    }
  } catch (error) {}
}

function* watchUserInfoSaga() {
  yield takeLatest(GET_USER_INFO_ACTION, getUserInfoSaga);
  yield takeLatest(GET_NOTIFICATION_ACTION, getNotificationSaga);
  yield takeLatest(CLEAR_NOTIFICATION_ACTION, clearNotificationSaga);
  yield takeLatest(GROUP_LIST_ACTION, getGroupListSaga);
  yield takeLatest(CREATE_GROUP_ACTION, createGroupSaga);
  yield takeLatest(UPDATE_USER_INFO, updateUserInfoSaga);
}
export default watchUserInfoSaga;
