import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";
import { useSelector } from "react-redux";
import { userDefaultAvatar } from "../../../../lib/dummyData";

const UserActions = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfoReducer.userInfo,
  }));

  const logout = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <NavItem tag={Dropdown} caret toggle={() => setVisible(!visible)}>
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3 pointer">
        <img
          className="rounded-circle mr-2"
          src={
            userInfo.profile_picture_url
              ? userInfo.profile_picture_url
              : userDefaultAvatar
          }
          alt="User Avatar"
          height="40"
          width="40"
          style={{ overflow: "hidden" }}
        />
        <span className="d-none d-md-inline-block">{userInfo.email}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem
          tag={NavLink}
          to="/"
          className="text-danger pointer"
          onClick={logout}
        >
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
};
export default UserActions;
