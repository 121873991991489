import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_CHART_ACTION,
  GET_CHART_ERROR,
  GET_CHART_LOADING,
  SMALL_CHART_SUCCESS,
  LINE_CHART_SUCCESS,
  PIE_CHART_SUCCESS,
  GET_PIE_DATA_ACTION,
  GET_PIE_DATA_ERROR,
  GET_PIE_DATA_SUCCESS,
  PIE_CHART_LIST_SUCCESS,
} from "./types";
import {
  delay,
  convertToLineFormat,
  convertToPieFormat,
  cleanText,
} from "../../lib/helper";
import { chartDataApi, chartTypesApi } from "./api";
import { Constant } from "../../lib/Constants";

function* getChartsSaga() {
  let errorFlag = false;
  yield put({ type: GET_CHART_LOADING, payload: true });
  try {
    let res = yield call(chartTypesApi);
    if (res.chart_types) {
      let smallCharts =
        res.chart_types[0].chart_type === Constant.CHART_TYPES.SMALL_CHART
          ? res.chart_types[0]
          : "";
      let lineCharts =
        res.chart_types[1].chart_type === Constant.CHART_TYPES.LINE_CHART
          ? res.chart_types[1]
          : "";
      let pieCharts =
        res.chart_types[2].chart_type === Constant.CHART_TYPES.PIE_CHART
          ? res.chart_types[2]
          : null;

      // ***********************
      // FOR SMALL CHARTS
      // ***********************
      let smallChartRes = [];
      for (let variation of smallCharts.chart_variations) {
        let res = yield call(chartDataApi, {
          chart_type: smallCharts.chart_type,
          chart_variation: variation,
        });
        smallChartRes.push(res);
      }
      yield put({ type: SMALL_CHART_SUCCESS, payload: smallChartRes });

      // ***********************
      // FOR LINE CHART
      // ***********************
      let lineChartRes = [];
      for (let variation of lineCharts.chart_variations) {
        let res = yield call(chartDataApi, {
          chart_type: lineCharts.chart_type,
          chart_variation: variation,
        });
        lineChartRes.push(res);
      }
      let lineChartdata = yield convertToLineFormat(lineChartRes);
      yield put({ type: LINE_CHART_SUCCESS, payload: lineChartdata });

      // ***********************
      // FOR PIE CHART
      // ***********************
      let pieChartRes = yield call(chartDataApi, {
        chart_type: pieCharts.chart_type,
      });
      let newPieChartType = pieCharts.chart_variations.map((type) =>
        cleanText(type)
      );
      yield put({
        type: PIE_CHART_LIST_SUCCESS,
        payload: newPieChartType,
      });

      let pieChartData = yield convertToPieFormat(
        pieChartRes.pie_chart_data,
        pieCharts.chart_variations[0]
      );
      yield put({ type: PIE_CHART_SUCCESS, payload: pieChartData });

      if (smallChartRes.length > 0 && lineChartRes.length > 0 && pieChartRes) {
        yield put({ type: GET_CHART_LOADING, payload: false });
      }
    } else {
      throw Error("Empty response");
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: GET_CHART_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: GET_CHART_ERROR, payload: "" });
    }
  }
}

function* getPieDataSaga(data) {
  let errorFlag = false;
  try {
    let res = yield call(chartDataApi, {
      chart_type: Constant.CHART_TYPES.PIE_CHART,
      chart_clicks: { ...data.payload },
    });
    if (res && res.pie_chart_data) {
      let pieChartData = yield convertToPieFormat(
        res.pie_chart_data,
        data.payload.curr_click
      );
      yield put({ type: GET_PIE_DATA_SUCCESS, payload: pieChartData });
    } else {
      errorFlag = true;
      yield put({
        type: GET_PIE_DATA_ERROR,
        payload: "Error while getting pie chart data",
      });
    }
  } catch (error) {
    errorFlag = true;
    yield put({ type: GET_PIE_DATA_ERROR, payload: error.toString() });
  } finally {
    if (errorFlag) {
      yield call(delay);
      yield put({ type: GET_PIE_DATA_ERROR, payload: "" });
    }
  }
}

function* watchAnalyticsSaga() {
  yield takeLatest(GET_CHART_ACTION, getChartsSaga);
  yield takeLatest(GET_PIE_DATA_ACTION, getPieDataSaga);
}
export default watchAnalyticsSaga;
