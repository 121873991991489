import coreApi from "../../lib/coreApi";

export let loginApi = (data) => {
  let url = "auth";
  let result = coreApi.POST(url, data);
  return result;
};

export let forgotPasswordApi = (data) => {
  let url = "forgot_password";
  let result = coreApi.POST(url, data);
  return result;
};
