import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Form,
  FormGroup,
  FormInput,
  FormFeedback,
  Button,
} from "shards-react";
import { Spinner } from "react-bootstrap";
import traqCheck_logo from "../../assets/traqCheck_logo_small.png";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordAction } from "../Login/action";
import { Error } from "../../components";
import { invalidEmail } from "../../lib/helper";

const ForgotPassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const {
    forgotPasswordLoading,
    forgotPasswordError,
    forgotPasswordSuccess,
  } = useSelector((state) => ({
    forgotPasswordLoading: state.loginReducer.forgotPasswordLoading,
    forgotPasswordSuccess: state.loginReducer.forgotPasswordSuccess,
    forgotPasswordError: state.loginReducer.forgotPasswordError,
  }));

  // FUNCTIONS
  const handleSubmit = () => {
    if (invalidEmail(email)) {
      setEmailError(invalidEmail(email));
    } else {
      setEmailError("");
      dispatch(forgotPasswordAction({ email, history: props.history }));
    }
  };

  const goBackLogin = () => {
    history.push("/login");
  };

  return (
    <>
      {forgotPasswordError && <Error error message={forgotPasswordError} />}
      {forgotPasswordSuccess && (
        <Error success message={forgotPasswordSuccess} />
      )}
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f6f8",
          flexDirection: "column",
        }}
      >
        <Card
          style={{
            width: 300,
            backgroundColor: "#ffffff",
            borderTop: "4px solid #007aff",
            borderRadius: "1rem",
            padding: 20,
            paddingTop: 0,
          }}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="text-center pb-2 pt-4">
              <img
                src={traqCheck_logo}
                style={{
                  width: 50,
                  height: 50,
                }}
                alt="logo"
              />
            </div>
            <div className="text-center pb-4">
              <span style={{ color: "black" }}>Reset password</span>
            </div>

            {/* INPUTS */}
            <FormGroup>
              <label htmlFor="email">Email address</label>
              <FormInput
                id="email"
                type="text"
                placeholder="Enter email"
                invalid={emailError.length > 0}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError ? <FormFeedback>{emailError}</FormFeedback> : null}
            </FormGroup>
            <div className="text-center">
              <span
                style={{ color: "#777", fontWeight: "lighter", fontSize: 12 }}
              >
                You will receive an email with a unique link
                to reset your password shortly.
              </span>
            </div>

            <div className="text-center mt-4 mb-2">
              <Button
                theme="primary"
                style={{ borderRadius: 100, minWidth: 100 }}
              >
                {forgotPasswordLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
          </Form>
        </Card>
        <div className="mt-2">
          <span
            style={{
              color: "#555",
              fontSize: 13,
              cursor: "pointer",
              fontWeight: "normal",
            }}
            onClick={goBackLogin}
          >
            Take me back to login.
          </span>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
