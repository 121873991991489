import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  FormTextarea,
  FormInput,
  FormGroup,
  FormFeedback,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
} from "shards-react";
import {
  PageTitle,
  CheckCard,
  MonitoringCard,
  SubmitCheck,
  Error,
  PageSpinner,
  AIComments,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { createCheckAction, getEstimatesAction } from "./action";
import { inputInvalid, convertToBase64 } from "../../lib/helper";
import { createCheckImage } from "../../assets/candidateDetail";
import { collarButtons, acceptTypes } from "../../lib/dummyData";
import { createCheckValidation } from "../../lib/validation";
import "../../styles/form.css";
import FileViewer from "react-file-viewer";

const CreateCheck = () => {
  const dispatch = useDispatch();

  // INFORMATION STATE
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCollar, setselectedCollar] = useState(1);
  const [premium, setPremium] = useState(false);
  const [monitoring, setMonitoring] = useState(false);
  const [fatherName, setFatherName] = useState("");
  const [address, setAddress] = useState("");
  const [imgAadhar, setImgAadhar] = useState("");
  const [imgDriving, setImgDriving] = useState("");
  const [unRequestedChecks, setUnRequestedChecks] = useState([]);

  const imgAadharRef = useRef(null);
  const imgDrivingRef = useRef(null);

  // Extra states
  const [dropdown, setDropdown] = useState(false);
  const [errors, setErrors] = useState("");

  const { error, loading, success } = useSelector((state) => ({
    error: state.createCheckReducer.error,
    loading: state.createCheckReducer.loading,
    success: state.createCheckReducer.success,
  }));

  const { estimateData } = useSelector((state) => ({
    estimateData: state.createCheckReducer.estimateData,
  }));

  const { groupList } = useSelector((state) => ({
    groupList: state.userInfoReducer.groupList,
  }));

  const clearState = useCallback(() => {
    setName("");
    setDesignation("");
    setDepartment("");
    setEmail("");
    setPhoneNumber("");
    setSelectedRole(groupList[0]);
    setPremium(false);
    setMonitoring(false);
    setFatherName("");
    setAddress("");
    setImgAadhar("");
    setImgDriving("");
  }, [groupList]);

  // Effect for Success
  useEffect(() => {
    if (success) clearState();
  }, [success, clearState]);

  // Effect for group list
  useEffect(() => {
    if (groupList.length > 0) setSelectedRole(groupList[0]);
  }, [groupList]);

  // Effect For Estimate data
  const estimateCall = useCallback(() => {
    dispatch(
      getEstimatesAction({
        candidate_details: {
          candidate_name: name,
          candidate_designation: designation,
          candidate_email: email,
          driving_license_photo_given_bool: imgDriving !== "",
        },
        candidate_group_id: selectedRole.candidate_group_id,
        package_selected: collarButtons[selectedCollar],
        traqcheck_premium: premium,
        traqcheck_id: {
          continuous_monitoring_enabled: monitoring,
        },
        manually_unrequested_checks: unRequestedChecks,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    monitoring,
    premium,
    selectedCollar,
    imgDriving,
    selectedRole,
    unRequestedChecks,
  ]);
  useEffect(() => {
    estimateCall();
  }, [
    selectedCollar,
    premium,
    monitoring,
    imgDriving,
    estimateCall,
    unRequestedChecks,
  ]);

  // FUNCTIONS
  const validateImages = (type, toUpdate) => {
    if (acceptTypes.includes(type)) {
      return true;
    } else {
      setErrors({
        ...errors,
        [toUpdate]: "File type not supported",
      });
      setTimeout(() => {
        setErrors({
          ...errors,
          [toUpdate]: "",
        });
      }, 3000);
    }
  };

  const handleSubmit = async () => {
    let vError = "";
    // BLUE COLLAR
    if (collarButtons[selectedCollar].value === collarButtons[0].value) {
      vError = createCheckValidation(
        name,
        designation,
        department,
        null,
        phoneNumber,
        fatherName,
        address,
        imgAadhar,
        null //imgDriving
      );
    }
    // WHITE COLLAR
    if (collarButtons[selectedCollar].value === collarButtons[1].value)
      vError = createCheckValidation(
        name,
        designation,
        department,
        email,
        phoneNumber,
        null,
        null,
        null,
        null
      );
    if (vError) {
      setErrors(vError);
      window.scrollTo(0, 0);
    } else {
      setErrors(vError);
      let whiteCollarData = {
        candidate_details: {
          candidate_name: name,
          candidate_designation: designation,
          candidate_department: department,
          candidate_email: email,
          candidate_phone_number: phoneNumber,
        },
        candidate_group_id: selectedRole.candidate_group_id,
        package_selected: collarButtons[selectedCollar].value,
        traqcheck_premium: premium,
        traqcheck_id: {
          continuous_monitoring_enabled: monitoring,
        },
        manually_unrequested_checks: unRequestedChecks,
      };
      let blueCollarData = {
        candidate_details: {
          candidate_name: name,
          candidate_designation: designation,
          candidate_department: department,
          candidate_phone_number: phoneNumber,
          father_name: fatherName,
          address: address,
          aadhaar_card_photo: imgAadhar.data,
          // driving_license_photo: imgDriving,
        },
        candidate_group_id: selectedRole.candidate_group_id,
        package_selected: collarButtons[selectedCollar].value,
        traqcheck_premium: premium,
        traqcheck_id: {
          continuous_monitoring_enabled: monitoring,
        },
        manually_unrequested_checks: unRequestedChecks,
      };
      let data = null;
      // BLUE COLLAR
      if (collarButtons[selectedCollar].value === collarButtons[0].value) {
        data = blueCollarData;
        if (imgDriving !== "") {
          data.candidate_details.driving_license_photo = imgDriving.data;
        }
      }
      // WHITE COLLAR
      else if (collarButtons[selectedCollar].value === collarButtons[1].value) {
        data = whiteCollarData;
      }
      dispatch(createCheckAction(data));
      window.scrollTo(0, 0);
    }
  };

  const handleBlur = () => {
    estimateCall();
  };

  const handleAadharChange = (e) => {
    let file = e.target.files[0];
    if (file && file.type) {
      if (validateImages(file.type, "aadhar")) {
        convertToBase64(file)
          .then((res) => {
            setImgAadhar({
              name: file.name,
              type: file.type,
              data: res,
            });
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleDrivingChange = (e) => {
    let file = e.target.files[0];
    if (file && file.type) {
      if (validateImages(file.type, "driving")) {
        convertToBase64(file)
          .then((res) => {
            setImgDriving({
              name: file.name,
              type: file.type,
              data: res,
            });
          })
          .catch((err) => console.log(err));
      }
    }
  };

  return (
    <>
      {loading ? (
        <PageSpinner />
      ) : (
        <>
          {error && <Error error message={error} />}
          {success && <Error success message={success} />}
          <Container fluid className="main-content-container px-4 pb-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
              <PageTitle
                sm="4"
                title="Create new check"
                className="text-sm-left"
              />
            </Row>

            {/* CREATE CANDIDATE FORM */}
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Candidate Information</h6>
                  </CardHeader>

                  <ListGroup flush>
                    <ListGroupItem className="p-3">
                      <Row>
                        <Col>
                          <Form>
                            {/* Candidate Name */}
                            <FormGroup>
                              <label htmlFor="feFirstName">
                                Candidate Name
                              </label>
                              <FormInput
                                id="feFirstName"
                                placeholder="Candidate Name"
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => handleBlur()}
                                value={name}
                                invalid={inputInvalid(errors, errors.name)}
                              />
                              {errors.name ? (
                                <FormFeedback>{errors.name}</FormFeedback>
                              ) : null}
                            </FormGroup>

                            {/* Designation */}
                            <FormGroup>
                              <label htmlFor="feDesignation">Designation</label>
                              <FormInput
                                id="feDesignation"
                                placeholder="Designation"
                                onChange={(e) => setDesignation(e.target.value)}
                                onBlur={() => handleBlur()}
                                value={designation}
                                invalid={inputInvalid(
                                  errors,
                                  errors.designation
                                )}
                              />
                              {errors.designation ? (
                                <FormFeedback>
                                  {errors.designation}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>

                            {/* Department */}
                            <FormGroup>
                              <label htmlFor="feDepartment">Department</label>
                              <FormInput
                                id="feDepartment"
                                placeholder="Department"
                                onChange={(e) => setDepartment(e.target.value)}
                                value={department}
                                invalid={inputInvalid(
                                  errors,
                                  errors.department
                                )}
                              />
                              {errors.department ? (
                                <FormFeedback>{errors.department}</FormFeedback>
                              ) : null}
                            </FormGroup>

                            {/* Email only for white collar */}
                            {collarButtons[selectedCollar].value ===
                              collarButtons[1].value && (
                              <FormGroup>
                                <label htmlFor="feEmail">Email</label>
                                <FormInput
                                  type="email"
                                  id="feEmail"
                                  placeholder="Email Address"
                                  onChange={(e) => setEmail(e.target.value)}
                                  onBlur={() => handleBlur()}
                                  value={email}
                                  invalid={inputInvalid(errors, errors.email)}
                                />
                                {errors.email ? (
                                  <FormFeedback>{errors.email}</FormFeedback>
                                ) : null}
                              </FormGroup>
                            )}
                            {/* Phone Number */}
                            <FormGroup>
                              <label htmlFor="fePhone Number">
                                Phone Number
                              </label>
                              <FormInput
                                type="number"
                                id="fePhone Number"
                                placeholder="Phone Number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                                invalid={inputInvalid(
                                  errors,
                                  errors.phoneNumber
                                )}
                              />
                              {errors.phoneNumber ? (
                                <FormFeedback>
                                  {errors.phoneNumber}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>

                            {/* Blue collar */}
                            {collarButtons[selectedCollar].value ===
                              collarButtons[0].value && (
                              <>
                                {/* Father name  */}
                                <FormGroup>
                                  <label htmlFor="id_father_name">
                                    Father's Name
                                  </label>
                                  <FormInput
                                    id="id_father_name"
                                    placeholder="Enter father's name"
                                    onChange={(e) =>
                                      setFatherName(e.target.value)
                                    }
                                    value={fatherName}
                                    invalid={inputInvalid(
                                      errors,
                                      errors.fatherName
                                    )}
                                  />
                                  {errors.fatherName ? (
                                    <FormFeedback>
                                      {errors.fatherName}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>

                                {/* Address */}
                                <FormGroup>
                                  <label htmlFor="id_address">Address</label>
                                  <FormTextarea
                                    id="id_address"
                                    placeholder="Enter Address"
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                    invalid={inputInvalid(
                                      errors,
                                      error.address
                                    )}
                                    style={{ resize: "none" }}
                                  />
                                  {errors.address ? (
                                    <FormFeedback>
                                      {errors.address}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>

                                {/* Aadhar */}
                                <FormGroup>
                                  <label htmlFor="aadhar" className="mr-4">
                                    Aadhar card (front)
                                  </label>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div>
                                      <input
                                        type="file"
                                        accept={acceptTypes}
                                        onChange={(e) => handleAadharChange(e)}
                                        ref={imgAadharRef}
                                        hidden
                                      />
                                      <Button
                                        size="sm"
                                        theme="white"
                                        onClick={() =>
                                          imgAadharRef.current.click()
                                        }
                                      >
                                        <i className="material-icons"></i>{" "}
                                        Upload Image
                                      </Button>
                                      {imgAadhar && (
                                        <span style={styles.imgName}>
                                          {imgAadhar.name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  {imgAadhar ? (
                                    <div style={styles.imageDiv}>
                                      <FileViewer
                                        key={imgAadhar.data}
                                        fileType={imgAadhar.type.split("/")[1]}
                                        filePath={imgAadhar.data}
                                      />
                                    </div>
                                  ) : null}
                                  {errors.aadhar ? (
                                    <span className="custom_error">
                                      {errors.aadhar}
                                    </span>
                                  ) : null}
                                </FormGroup>

                                {/* Driving license */}
                                <FormGroup>
                                  <label htmlFor="aadhar">
                                    Driving License (front)
                                  </label>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div>
                                      <input
                                        type="file"
                                        accept={acceptTypes}
                                        onChange={(e) => handleDrivingChange(e)}
                                        ref={imgDrivingRef}
                                        hidden
                                      />
                                      <Button
                                        size="sm"
                                        theme="white"
                                        onClick={() =>
                                          imgDrivingRef.current.click()
                                        }
                                      >
                                        <i className="material-icons"></i>{" "}
                                        Upload Image
                                      </Button>
                                    </div>
                                    {imgDriving && (
                                      <span style={styles.imgName}>
                                        {imgDriving.name}
                                      </span>
                                    )}
                                  </div>
                                  <>
                                    {imgDriving ? (
                                      <div style={styles.imageDiv}>
                                        <FileViewer
                                          key={imgDriving.data}
                                          fileType={
                                            imgDriving.type.split("/")[1]
                                          }
                                          filePath={imgDriving.data}
                                        />
                                      </div>
                                    ) : null}
                                    {errors.driving ? (
                                      <span className="custom_error">
                                        {errors.driving}
                                      </span>
                                    ) : null}
                                  </>
                                </FormGroup>
                              </>
                            )}
                          </Form>
                        </Col>

                        {/* Role Dropdown */}
                        <Col xs lg="5">
                          <Dropdown
                            open={dropdown}
                            toggle={() => setDropdown(!dropdown)}
                          >
                            <DropdownToggle
                              caret
                              style={{
                                backgroundColor: "transparent",
                                color: "#000",
                                fontSize: "0.9em",
                                letterSpacing: "0.5px",
                                borderColor: "transparent",
                              }}
                            >
                              {selectedRole.candidate_group_name}
                            </DropdownToggle>
                            <DropdownMenu>
                              {groupList
                                ? groupList.map((role, i) => (
                                    <DropdownItem
                                      key={i}
                                      onClick={() => setSelectedRole(role)}
                                    >
                                      {role.candidate_group_name}
                                    </DropdownItem>
                                  ))
                                : null}
                            </DropdownMenu>
                          </Dropdown>

                          {/* Image  */}
                          <div className="roleImageDiv p-2 pt-4">
                            <img
                              src={createCheckImage}
                              alt="role"
                              height="80%"
                              width="80%"
                            />
                          </div>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </Col>
            </Row>

            {/* checks and monitoring */}
            <Row>
              <Col sm={12} md={12} lg={6}>
                <CheckCard
                  createCheck
                  checksList={estimateData.checks_conducted}
                  unRequestedChecks={unRequestedChecks}
                  setUnRequestedChecks={setUnRequestedChecks}
                  // COLLAR BUTTONS
                  collarButtons={collarButtons}
                  selectedCollarHandle={(i) => {
                    setUnRequestedChecks([]);
                    setselectedCollar(i);
                  }}
                  selectedCollarValue={selectedCollar}
                />
              </Col>
              <Col sm={12} md={12} lg={6}>
                <MonitoringCard
                  createCheck
                  monitoringValue={monitoring}
                  monitoringChange={() => setMonitoring(!monitoring)}
                />
                <AIComments
                  aiComments={
                    estimateData.traqcheck_ai_comments
                      ? estimateData.traqcheck_ai_comments
                      : "No comments to show"
                  }
                />
                <SubmitCheck
                  eta={estimateData.eta ? estimateData.eta : "0"}
                  price={estimateData.cost ? estimateData.cost : "0"}
                  handleCheckSubmit={handleSubmit}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

const styles = {
  imageDiv: {
    marginTop: 10,
    height: 200,
    width: 500,
  },
  imgName: { marginLeft: 20, marginTop: 5 },
};

export default CreateCheck;
