import createReducer from "../../lib/createReducer";
import {
  GET_SPECIFIC_CHECK_ERROR,
  GET_SPECIFIC_CHECK_LOADING,
  GET_SPECIFIC_CHECK_SUCCESS,
} from "./types";

let initialState = {
  error: "",
  loading: false,
  checkData: {},
};

export const candidateDetailReducer = createReducer(initialState, {
  [GET_SPECIFIC_CHECK_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: action.payload,
      error: "",
      checkData: {},
    });
  },
  [GET_SPECIFIC_CHECK_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      error: "",
      checkData: action.payload,
    });
  },
  [GET_SPECIFIC_CHECK_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      error: action.payload,
      checkData: {},
    });
  },
});
