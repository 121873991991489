import { Constant } from "../../lib/Constants";
import coreApi from "../../lib/coreApi";

export const updateCandidateGroup = (data) => {
  let url = "checks";
  let result = coreApi.PATCH(url, data);
  return result;
};

export const candidatesByGroupApi = (candidateGroupId, page, search, statuses) => {
  let qs = {
    candidate_group_id: candidateGroupId,
    page,
  };
  if(search) {
    qs.search = search;
  }
  if(Array.isArray(statuses) && statuses.length) {
    qs.statuses = statuses;
  }
  const params = new URLSearchParams(qs);
  let url = `paginated_checks_candidate_group?${params.toString()}`;
  let result = coreApi.GET(url);
  return result;
};

export const updateMonitoringApi = (data) => {
  let url = "checks";
  let result = coreApi.PATCH(url, data);
  return result;
};

export const supportApi = (data) => {
  let url = "contact_support";
  let result = coreApi.POST(url, data);
  return result;
};

export const pdfReportZipApi = (data) => {
  let url = "pdf_reports_zip_action";
  let result;
  if (data?.action === Constant.ZIP_FILE_ACTIONS.CONSUME) {
    result = coreApi.DOWNLOAD_ZIP(url, data);
  } else {
    result = coreApi.POST(url, data);
  }
  return result;
};
