import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import { PageSpinner, Error, PageTitle } from "../../components";
import {
  SettingsHeader,
  Permissions,
  AccessHub,
  ChangePassword,
} from "../../components/Setting";
import { useDispatch, useSelector } from "react-redux";
import { getOrgUsersAction, emailNotificationAction } from "./action";

const Settings = () => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => ({
    userInfo: state.userInfoReducer.userInfo,
  }));

  const { loading, error, orgUsers } = useSelector((state) => ({
    loading: state.settingsReducer.loading,
    error: state.settingsReducer.error,
    orgUsers: state.settingsReducer.orgUsers,
    permissionSuccess: state.settingsReducer.permissionSuccess,
  }));

  const [notificationCheck, setNotificationCheck] = useState(true);

  useEffect(() => {
    dispatch(getOrgUsersAction());
    setNotificationCheck(userInfo.has_email_notifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUNCTIONS
  const handleNotificationChange = () => {
    dispatch(
      emailNotificationAction({ has_email_notifications: !notificationCheck })
    );
  };

  return (
    <>
      {loading ? (
        <PageSpinner />
      ) : (
        <>
          {error && <Error error message={error} />}
          <Container fluid className="main-content-container px-4 pb-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
              <PageTitle sm="4" title="Settings" className="text-sm-left" />
            </Row>
            {/* settings header */}
            <Row>
              <Col>
                <SettingsHeader
                  email={userInfo.email}
                  isAdmin={userInfo.is_admin}
                  organization={userInfo.org_name}
                />
              </Col>
            </Row>

            {/* permissons and access hub */}
            <Row>
              {userInfo.permissions && userInfo.permissions.length > 0 ? (
                <Col xl={6}>
                  <Permissions permissionsData={userInfo.permissions} />
                </Col>
              ) : null}
              <Col>
                <AccessHub
                  isAdmin={userInfo.is_admin}
                  orgUsersData={orgUsers}
                />
              </Col>
            </Row>

            {/* send notifications and change password */}
            <Row>
              <Col>
                <ChangePassword
                  handleNotification={() => handleNotificationChange()}
                  notificationValue={notificationCheck}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Settings;
