export const GET_USER_INFO_ACTION = "GET_USER_INFO_ACTION";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_LOADING = "GET_USER_INFO_LOADING";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";

export const GET_NOTIFICATION_ACTION = "GET_NOTIFICATION_ACTION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

export const CLEAR_NOTIFICATION_ACTION = "CLEAR_NOTIFICATION_ACTION";
export const CLEAR_NOTIFICATION_SUCCESS = "CLEAR_NOTIFICATION_SUCCESS";
export const CLEAR_NOTIFICATION_ERROR = "CLEAR_NOTIFICATION_ERROR";

export const GROUP_LIST_ACTION = "GROUP_LIST_ACTION";
export const GROUP_LIST_LOADING = "GROUP_LIST_LOADING";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
export const GROUP_LIST_ERROR = "GROUP_LIST_ERROR";

export const CREATE_GROUP_ACTION = "CREATE_GROUP_ACTION";
export const CREATE_GROUP_LOADING = "CREATE_GROUP_LOADING";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
