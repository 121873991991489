import {
  GET_USER_INFO_ACTION,
  GET_NOTIFICATION_ACTION,
  CLEAR_NOTIFICATION_ACTION,
  GROUP_LIST_ACTION,
  CREATE_GROUP_ACTION,
  UPDATE_USER_INFO,
} from "./types";

export const getUserInfo = () => ({
  type: GET_USER_INFO_ACTION,
});

export const getNotification = () => ({
  type: GET_NOTIFICATION_ACTION,
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION_ACTION,
});

export const getGroupList = () => ({
  type: GROUP_LIST_ACTION,
});

export const createGroupAction = (data) => ({
  type: CREATE_GROUP_ACTION,
  payload: data,
});

export const updateUserInfoAction = () => ({
  type: UPDATE_USER_INFO,
});
