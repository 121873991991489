import React from "react";
import { Spinner } from "react-bootstrap";
import { Container } from "shards-react";

const PageSpinner = () => {
  return (
    <Container
      fluid
      className="main-content-container px-4 pb-4"
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner animation="border" />
    </Container>
  );
};

export default PageSpinner;
